import React, { useState } from 'react';
import {
  List, ListItem, CircularProgress,
  TextField
} from '@mui/material';
import API from 'common/api';
import { useSnackbar } from 'notistack';
import SnackMessage from 'components/SnackMessage/SnackMessage';
import { useNavigate } from 'react-router';
import PAGES from 'common/pages';
import { put } from 'helpers/query/fetchers';
import useConnectErrorHandler from 'helpers/errorHandlers';
import { Button } from 'bso-ui';
import { ArrowForward } from '@mui/icons-material';

const initialFormState = {
  current_password: '',
  password: '',
  confirm_password: '',
};

const initialFormErrorState = {
  current_password: '',
  password: '',
  confirm_password: '',
};

const ChangePasswordForm = () => {
  const [formState, setFormState] = useState(initialFormState);
  const [formErrorState, setFormErrorState] = useState(initialFormErrorState);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { handleError } = useConnectErrorHandler();

  const handleChange = (name, value) => {
    setFormState({ ...formState, [name]: value });
  };

  const isError = (error) => Boolean(error);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const url = API.CHANGE_PASSWORD;

    put(url, { ...formState }).then((res) => {
      enqueueSnackbar('', {
        content: (key) => (
          <SnackMessage
            data-testid="profile-change-msg"
            id={key}
            message={res.data.message}
            variant="success"
          />
        )
      });
      navigate(PAGES.SIGN_OUT, { state: { from: { pathname: PAGES.DASHBOARD } } });
    }).catch((err) => {
      handleError(err);
      setFormErrorState(err.response.data);
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
      >
        <List disablePadding>
          <ListItem style={{ marginTop: 8, marginBottom: 8 }}>
            {/* <FormField */}
            <TextField
              data-testid="current-password-input"
              label="Current password"
              name="current_password"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              type="password"
              variant="outlined"
              fullWidth
              value={formState.current_password}
              error={isError(formErrorState.current_password)}
              helperText={formErrorState.current_password ? formErrorState.current_password : null}
            />
          </ListItem>
          <ListItem style={{ marginTop: 8, marginBottom: 8 }}>
            {/* <FormField */}
            <TextField
              data-testid="password-input"
              label="New password"
              name="password"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              type="password"
              variant="outlined"
              fullWidth
              value={formState.password}
              error={isError(formErrorState.password)}
              helperText={formErrorState.password ? formErrorState.password : null}
            />
          </ListItem>
          <ListItem style={{ marginTop: 8, marginBottom: 8 }}>
            {/* <FormField */}
            <TextField
              data-testid="confirm-password-input"
              label="Confirm new password"
              name="confirm_password"
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              type="password"
              variant="outlined"
              fullWidth
              value={formState.confirm_password}
              error={isError(formErrorState.confirm_password)}
              helperText={formErrorState.confirm_password ? formErrorState.confirm_password : null}
            />
          </ListItem>
        </List>
        <div style={{ display: 'flex', marginTop: 40 }}>
          <Button
            id="ChangePasswordButton"
            variant="cta"
            color="primary"
            state="solid"
            disabled={loading
              || !formState.current_password || !formState.password || !formState.confirm_password}
            endIcon={!loading ? <ArrowForward /> : <CircularProgress size={20} />}
            type="submit"
          >
            Change password
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ChangePasswordForm;
