import React from 'react';
import { Box } from '@mui/material';
import PAGES from 'common/pages';
import { Typography, ExternalLink } from 'bso-ui';

const Footer = () => (
  <Box display="flex" alignItems="center" p={4}>
    <Box marginRight={2}>
      <Typography variant="body2" color="primary">
        &copy;{' '}
        2022 BSO All rights reserved
      </Typography>
    </Box>
    <span style={{ flex: 1 }} />
    <Box marginRight={2}>
      <ExternalLink
        underline="none"
        component="a"
        href={PAGES.BSO_TERMS_OF_USE}
      >
        <Typography variant="body2" color="primary">
          Terms of use
        </Typography>
      </ExternalLink>
    </Box>
    <Box marginRight={2}>
      <ExternalLink
        underline="none"
        component="a"
        href={PAGES.BSO_PRIVACY_POLICY}
      >
        <Typography variant="body2" color="primary">
          Privacy policy
        </Typography>
      </ExternalLink>
    </Box>
    <Box marginRight={2}>
      <ExternalLink
        underline="none"
        component="a"
        href={PAGES.BSO_COOKIE_POLICY}
      >
        <Typography variant="body2" color="primary">
          Cookie policy
        </Typography>
      </ExternalLink>
    </Box>
    <Box marginRight={2}>
      <ExternalLink
        underline="none"
        component="a"
        href={PAGES.BSO_GROUP_POLICY}
      >
        <Typography variant="body2" color="primary">
          Group policy
        </Typography>
      </ExternalLink>
    </Box>
  </Box>
);

export default Footer;
