import React, { forwardRef } from 'react';
import { makeStyles } from '@mui/styles';

import { Box, LinearProgress, Tooltip } from '@mui/material';
import { Typography } from 'bso-ui';
import { normalise } from 'helpers/formatters';

const getColor = (theme, status) => {
  if (status === 'error') {
    return theme.palette.error.main;
  }

  return theme.palette.bsoGrey;
};

const useStyles = makeStyles((theme) => ({
  buffer: {
    height: 32,
    border: '1px solid rgba(64, 64, 124, .1)',
    '&.MuiLinearProgress-determinate': {
      background: (props) => getColor(theme, props.status)
    },
    '&.MuiLinearProgress-indeterminate': {
      background: theme.palette.bsoGrey
    },
    '& .MuiLinearProgress-barColorPrimary': {
      background: theme.palette.background.gradient
    },
    '&.MuiLinearProgress-bar1Buffer': {
      background: theme.palette.background.gradient
    },
    '&.MuiLinearProgress-bar2Buffer': {
      background: theme.palette.primary.main
    },
    '&.MuiLinearProgress-buffer': {
      background: (props) => getColor(theme, props.status),
    },
    '& .MuiLinearProgress-dashedColorPrimary': {
      backgroundImage: 'none'
    }
  },
  threshold: {
    height: 32,
    border: '1px solid rgba(64, 64, 124, .1)',
    '&.MuiLinearProgress-determinate': {
      background: theme.palette.bsoGrey
    },
    '& .MuiLinearProgress-barColorPrimary': {
      background: theme.palette.success.main
    },
    '& .MuiLinearProgress-colorPrimary': {
      background: theme.palette.warning.main
    },
    '&.MuiLinearProgress-bar1Buffer': {
      background: theme.palette.success.main
    },
    '&.MuiLinearProgress-bar2Buffer': {
      background: theme.palette.warning.main
    },
    '&.MuiLinearProgress-buffer': {
      background: theme.palette.error.main,
      border: theme.palette.bsoGrey
    },
    '& .MuiLinearProgress-dashedColorPrimary': {
      backgroundImage: 'none'
    }
  }
}));

export const CustomLinearProgress = ({
  forwardedRef,
  variant,
  current,
  max,
  total,
  mode = null,
  ...props
}) => {
  const classes = useStyles(props);
  let title = `Buffer usage: ${current}/${total} (Max: ${max})`;
  let textColor = 'textPrimary';
  let textValue = `${current}/${total}`;
  let barValue = normalise(current, 0, total);
  let barMaxValue = normalise(max, 0, total);
  let className = classes.buffer;
  if (props.status === 'error') {
    textColor = 'white';
    textValue = 'Disabled';
    barValue = 0;
    barMaxValue = 0;
  }
  if (mode === 'threshold') {
    title = `Warning: ${max} | Critical: ${total}`;
    textValue = `${max}/${total}`;
    className = classes.threshold;
  }

  return (
    <Tooltip title={title}>
      <Box position="relative" width="100%">
        <Box position="absolute" zIndex={800} textAlign="center" width="100%">
          <Typography variant="body2" color={textColor} style={{ fontWeight: 500, marginTop: 4 }} component="div">
            {textValue}
          </Typography>
        </Box>
        <LinearProgress
          ref={forwardedRef}
          {...props}
          variant={variant}
          value={barValue}
          valueBuffer={barMaxValue}
          className={className}
        />
      </Box>
    </Tooltip>
  );
};

export default forwardRef((props, ref) => <CustomLinearProgress {...props} forwardedRef={ref} />);
