import React from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Tooltip, CircularProgress } from '@mui/material';
import { Refresh as RefreshIcon, Add, PermDataSetting } from '@mui/icons-material';
import PAGES from 'common/pages';
import { IconButton } from 'bso-ui';
import Toolbar from 'components/Toolbar/Toolbar';
import DeviceFilter from './Form/DeviceFilter';
import DeviceCreate from './Form/DeviceCreate';

const DeviceToolbar = ({
  title = '', isFetching, refetch, backLink, data = null, userData = null
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Toolbar
      title={title}
      backLink={backLink}
      action={(
        <>
          {data && userData.is_staff ? (
            <DeviceCreate
              title="Create Device"
              button={{
                content: (
                  <>
                    <Add />
                    Create
                  </>
                ),
                color: 'primary',
                variant: 'standard',
                state: 'outlined'
              }}
            />
          ) : null}
          {isFetching ? (
            <div style={{
              height: 48, width: 48, display: 'flex', alignItems: 'center', justifyContent: 'center'
            }}
            >
              <CircularProgress size={22} />
            </div>
          ) : (
            <Tooltip title="Sync">
              <div>
                <IconButton
                  id="SyncButton"
                  onClick={(e) => {
                    e.preventDefault();
                    refetch();
                  }}
                >
                  <RefreshIcon />
                </IconButton>
              </div>
            </Tooltip>
          )}
          <DeviceFilter />
          <Tooltip title="View Threshold Defaults">
            <div>
              <IconButton
                onClick={() => {
                  navigate(`${PAGES.DEFAULT_THRESHOLD_MGMT}${location.search}`);
                }}
              >
                <PermDataSetting />
              </IconButton>
            </div>
          </Tooltip>
        </>
      )}
    />
  );
};
export default DeviceToolbar;
