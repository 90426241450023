import React from 'react';
import { makeStyles } from '@mui/styles';

import { Chip } from '@mui/material';
import capitalize from 'lodash/capitalize';

const useStyles = makeStyles((theme) => ({
  outlinedSuccess: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.main
  },
  outlinedError: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main
  },
  outlinedWarning: {
    borderColor: theme.palette.warning.main,
    color: theme.palette.warning.main
  },
  outlinedInfo: {
    borderColor: theme.palette.info.main,
    color: theme.palette.info.main
  },
  defaultSuccess: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText
  },
  defaultError: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText
  },
  defaultWarning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText
  },
  defaultInfo: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  }
}));

export const CustomChip = ({
  color = 'default',
  variant = 'default',
  size = 'small',
  ...props
}) => {
  const classes = useStyles();
  const className = classes?.[`${variant}${capitalize(color)}`];

  return (
    <Chip {...props} size={size} variant={variant} className={className} />
  );
};

export default CustomChip;
