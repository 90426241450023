import React from 'react';
import { useParams, useLocation, useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import {
  Box, Card, CardHeader, CardContent, CardActions
} from '@mui/material';
import useUser from 'hooks/useUser';
import { Button, Typography } from 'bso-ui';
import useConnectErrorHandler from 'helpers/errorHandlers';
import DeleteConfirmationDialog from 'components/DeleteConfirmationDialog/DeleteConfirmationDialog';
import DRFTable from 'components/DRFTable/DRFTable';
import DRFRow from 'components/DRFTable/DRFRow';
import DRFCell from 'components/DRFTable/DRFCell';
import API from 'common/api';
import useLoading from 'context/LoadingContext';
import SnackMessage from 'components/SnackMessage/SnackMessage';
import useDetail from 'hooks/useDetail';
import { PlaceHolderText } from 'components/PlaceholderCard/PlaceholderCard';
import PAGES from 'common/pages';
import { del } from 'helpers/query/fetchers';
import { VITE_PORTAL_API } from 'config';
import InterfaceToolbar from './InterfaceToolbar';
import InterfaceCreate from './Form/InterfaceCreate';

const InterfaceInfo = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const height = '80vh';
  const [{ data: userData }] = useUser();
  const { handleError } = useConnectErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const { loading, setLoading } = useLoading();

  const query = {
    storeKey: `interface-detail-${id}`,
    apiEndpoint: `${API.INTERFACE}${id}/`,
  };
  const [{
    data, isLoading, isFetching, isError, refetch
  }] = useDetail(query.storeKey, query.apiEndpoint);

  const deleteInterface = () => {
    setLoading(true);
    const url = `${VITE_PORTAL_API}${API.INTERFACE}${id}/`;
    del(url)
      .then(() => {
        navigate(`${PAGES.INTERFACE_MGMT}${location.search}`);
        const msg = `INTERFACE ID: ${id} has been Deleted.`;

        enqueueSnackbar(msg, {
          content: (key) => <SnackMessage id={key} message={msg} variant="success" />
        });
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getActionButtons = () => (
    <>
      {data && userData.is_staff ? (
        <InterfaceCreate
          title="Update Interface"
          data={data}
          button={{
            content: 'Update',
            variant: 'standard',
            state: 'outlined',
            color: 'primary',
            disabled: isFetching
          }}
          formType="update"
          refetch={refetch}
        />
      ) : (
        <div style={{ paddingTop: '36px' }} />
      )}
      {data && userData.is_staff ? (
        <DeleteConfirmationDialog
          id="DeleteInterfaceHeader"
          title="Delete Interface"
          contentText="Are you sure you want to delete this Interface?"
          confirmText="Yes"
        >
          {(confirm) => (
            <Button
              id="DeleteInterfaceButton"
              color="primary"
              variant="standard"
              state="outlined"
              disabled={isFetching}
              onClick={confirm(deleteInterface)}
            >
              Delete
            </Button>
          )}
        </DeleteConfirmationDialog>
      ) : (
        <div style={{ paddingTop: '36px' }} />
      )}
    </>
  );

  if (data) {
    return (
      <Box p={3}>
        <InterfaceToolbar
          title="Interface Information"
          isFetching={isFetching}
          refetch={refetch}
          backLink={PAGES.INTERFACE_MGMT}
          data={data}
          userData={userData}
        />
        <Card style={{ height, overflow: 'auto' }}>
          <CardHeader
            title={(
              <Typography variant="h4" color="primary">
                INTERFACE ID: {data.id}
              </Typography>
            )}
            style={{ paddingBottom: 0 }}
          />
          <CardContent
            style={{
              paddingTop: 0,
              overflowY: 'auto'
            }}
          >
            <div style={{ paddingBottom: '16px' }}>
              <Typography variant="subtitle1">{data.name}</Typography>
            </div>
            <DRFTable>
              <DRFRow>
                <DRFCell width={5}>
                  <Typography variant="body2">Device</Typography>
                </DRFCell>
                <DRFCell width={7}>
                  <Typography>{data.device.hostname ? data.device.hostname : '---'}</Typography>
                </DRFCell>
              </DRFRow>
              <DRFRow>
                <DRFCell width={5}>
                  <Typography variant="body2">Description</Typography>
                </DRFCell>
                <DRFCell width={7}>
                  <Typography>{data.description ? data.description : '---'}</Typography>
                </DRFCell>
              </DRFRow>
              <DRFRow>
                <DRFCell width={5}>
                  <Typography variant="body2">Type</Typography>
                </DRFCell>
                <DRFCell width={7}>
                  <Typography>{data.type && data.type.name ? data.type.name : '---'}</Typography>
                </DRFCell>
              </DRFRow>
              <DRFRow>
                <DRFCell width={5}>
                  <Typography variant="body2">Activation Date</Typography>
                </DRFCell>
                <DRFCell width={7}>
                  <Typography>{data.activation_date ? data.activation_date : '---'}</Typography>
                </DRFCell>
              </DRFRow>
              <DRFRow>
                <DRFCell width={5}>
                  <Typography variant="body2">Termination Date</Typography>
                </DRFCell>
                <DRFCell width={7}>
                  <Typography>{data.termination_date ? data.termination_date : '---'}</Typography>
                </DRFCell>
              </DRFRow>
              <DRFRow>
                <DRFCell width={5}>
                  <Typography variant="body2">Active</Typography>
                </DRFCell>
                <DRFCell width={7}>
                  <Typography>{data.is_active ? 'Yes' : 'No'}</Typography>
                </DRFCell>
              </DRFRow>
            </DRFTable>
          </CardContent>
          <CardActions>{getActionButtons()}</CardActions>
        </Card>
      </Box>
    );
  }

  if (isLoading || loading) return <PlaceHolderText content="Loading..." height={height} />;

  if (data === null || id === 'no-selected') {
    return <PlaceHolderText content="Interface ID Not found. Please select an item." height={height} />;
  }

  if (isError) {
    return (
      <PlaceHolderText
        id="ErrorInterfaceContentText"
        content="Error getting interface information."
        height={height}
      />
    );
  }

  return <div />;
};

export default InterfaceInfo;
