import React, { forwardRef } from 'react';
import {
  Outlet,
  NavLink as RouterLink,
  useLocation
} from 'react-router';
import { makeStyles } from '@mui/styles';
import {
  List, ListItem, Drawer, ListItemText, ListSubheader,
} from '@mui/material';
import PAGES from 'common/pages';
import useUser from 'hooks/useUser';
import { isAppAdmin } from 'helpers/permissions';

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: 1000
  },
  drawerContainer: {
    paddingTop: 100,
    overflow: 'auto',
    height: '100%'
  },
  listItemRoot: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  selected: {
    '&$selected': {
      borderLeft: `4px solid ${theme.palette.primary.main}`,
    }
  },
  content: {
    paddingTop: 16,
    minWidth: 1000,
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth})px`
  }
}));

const CustomRouterRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ width: '100%' }}
  >
    <RouterLink {...props} />
  </div>
));

const Settings = () => {
  const classes = useStyles();
  const location = useLocation();
  const [{ data: userData, isLoading }] = useUser();
  const isSelected = (selectedPage) => {
    const path = location.pathname.split('/');
    if (path.length > 3) {
      path.pop();
      return selectedPage === path.join('/');
    }
    return selectedPage === location.pathname;
  };

  return (
    <>
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerContainer}>
          {(!userData?.is_staff && !userData?.is_superuser) &&
            <List>
              <ListItem
                id="AccountProfileLink"
                button
                component={CustomRouterRouterLink}
                to={{
                  pathname: PAGES.ACCOUNT,
                }}
                selected={isSelected(PAGES.ACCOUNT)}
                classes={{
                  root: classes.listItemRoot,
                  selected: classes.selected
                }}
              >
                <ListItemText primary="Account profile" />
              </ListItem>
            </List>
          }
          {!isLoading && userData
            && (isAppAdmin(userData.groups) || userData.is_staff)
            ? (
              <List subheader={<ListSubheader disableSticky>Administration</ListSubheader>}>
                <ListItem
                  button
                  component={CustomRouterRouterLink}
                  to={{
                    pathname: PAGES.INTERFACE_MGMT,
                  }}
                  selected={isSelected(PAGES.INTERFACE_MGMT)}
                  classes={{
                    root: classes.listItemRoot,
                    selected: classes.selected
                  }}
                >
                  <ListItemText primary="Interface management" />
                </ListItem>
                <ListItem
                  button
                  component={CustomRouterRouterLink}
                  to={{
                    pathname: PAGES.USER_MGMT,
                  }}
                  selected={isSelected(PAGES.USER_MGMT)}
                  classes={{
                    root: classes.listItemRoot,
                    selected: classes.selected
                  }}
                >
                  <ListItemText primary="User management" />
                </ListItem>
                {(userData?.is_staff || userData?.is_superuser) && (
                  <ListItem
                    data-testid="device-management-link"
                    button
                    component={CustomRouterRouterLink}
                    to={{
                      pathname: PAGES.DEVICE_MGMT,
                    }}
                    selected={isSelected(PAGES.DEVICE_MGMT) || isSelected(PAGES.DEFAULT_THRESHOLD_MGMT)}
                    classes={{
                      root: classes.listItemRoot,
                      selected: classes.selected
                    }}
                  >
                    <ListItemText primary="Device management" />
                  </ListItem>
                )}
                <ListItem
                  button
                  component={CustomRouterRouterLink}
                  to={{
                    pathname: PAGES.LOGS,
                  }}
                  selected={isSelected(PAGES.LOGS)}
                  classes={{
                    root: classes.listItemRoot,
                    selected: classes.selected
                  }}
                >
                  <ListItemText primary="Logs" />
                </ListItem>
              </List>
            )
            : null}
        </div>
      </Drawer>
      <div className={classes.content}>
        {/* <Routes>
          <Route path={PAGES.SETTINGS} element={<Navigate replace to={PAGES.ACCOUNT} />} />

        <Route
          path={PAGES.ACCOUNT}
          title="Account Profile"
          element={(
            <PrivateLayoutWrapper
              component={Account}
              layout={Box}
            />
          )}
        />
        <Route
          path={PAGES.INTERFACE_MGMT}
          element={(
            <PrivateLayoutWrapper
              component={InterfacesView}
              layout={Box}
            />
          )}
        />
        <Route
          path={PAGES.INTERFACE_DETAIL}
          element={(
            <PrivateLayoutWrapper
              component={InterfacesDetailView}
              layout={Box}
            />
          )}
        />
        <Route
          path={PAGES.USER_MGMT}
          element={(
            <PrivateLayoutWrapper
              component={UsersView}
              layout={Box}
            />
          )}
        />
        <Route
          path={PAGES.USER_DETAIL}
          element={(
            <PrivateLayoutWrapper
              component={UsersDetailView}
              layout={Box}
            />
          )}
        />
        <Route
          path={PAGES.DEVICE_MGMT}
          element={(
            <PrivateLayoutWrapper
              component={DevicesView}
              layout={Box}
            />
          )}
        />
        <Route
          path={PAGES.DEVICE_DETAIL}
          element={(
            <PrivateLayoutWrapper
              component={DevicesDetailView}
              layout={Box}
            />
          )}
        />
        <Route
          path={PAGES.DEFAULT_THRESHOLD_MGMT}
          element={(
            <PrivateLayoutWrapper
              component={DefaultThresholdView}
              layout={Box}
            />
          )}
        />
        <Route
          path={PAGES.LOGS}
          element={(
            <PrivateLayoutWrapper
              component={Logs}
              layout={Box}
            />
          )}
        />
        <Route path="*" element={<Navigate replace to={PAGES.NOT_FOUND} />} />
      </Routes> */}
        <Outlet />
      </div>
    </>
  );
};

export default Settings;
