import moment from 'moment';
import { signinPost } from 'helpers/query/fetchers';
import API from 'common/api';
import useConnectErrorHandler from 'helpers/errorHandlers';
import { useState } from 'react';
import { useNavigate } from 'react-router';

const useAuth = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { handleSignInError } = useConnectErrorHandler();

  const getToken = () => {
    localStorage.getItem('token');
  };

  const setToken = (token) => {
    localStorage.setItem('token', token);
  };

  const signIn = (credentials) => {
    setIsLoading(true);
    signinPost(API.LOGIN, credentials)
      .then((res) => {
        setToken(res.data.access);
        setIsLoading(false);
        navigate('/');
      })
      .catch((error) => {
        handleSignInError(error);
        setIsLoading(false);
      });
  };

  const signOut = () => {
    localStorage.removeItem('token');
  };

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  const isSignedIn = () => {
    const tokenPayload = parseJwt(localStorage.getItem('token'));
    if (tokenPayload) {
      const expireOn = moment
        .unix(tokenPayload.exp)
        .utc();
      return new Date().getTime() < expireOn;
    }
    return false;
  };

  return {
    signIn,
    isLoading,
    isSignedIn,
    getToken,
    setToken,
    signOut,
    parseJwt
  };
};

export default useAuth;
