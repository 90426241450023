import React from 'react';
import {
  Box,
  Grid,
  Card,
  CardHeader, CardContent
} from '@mui/material';
import Progress from 'components/Progress/Progress';
import { Typography } from 'bso-ui';
import DRFTable from 'components/DRFTable/DRFTable';
import DRFRow from 'components/DRFTable/DRFRow';
import DRFCell from 'components/DRFTable/DRFCell';
import CustomLinearProgress from 'components/CustomLinearProgress/CustomLinearProgress';

const ShareUser = ({ data, priority }) => {
  const height = '65vh';

  const renderData = () => (
    <Box px={3}>
      <Typography variant="h5">User</Typography>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item lg={6} xs={12}>
          <Card style={{ height }}>
            <CardHeader
              disableTypography
              title={<Typography variant="h6">Preprocess</Typography>}
              style={{ paddingBottom: 0 }}
            />
            <CardContent style={{ paddingTop: 0, overflowY: 'auto' }}>
              <DRFTable>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Received Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.rxPreprocessReceivedFrameCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Compressed Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.rxPreprocessCompressedFrameCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Padded Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.rxPreprocessPaddedFrameCount}</Typography>
                  </DRFCell>
                </DRFRow>
              </DRFTable>
            </CardContent>
            <CardHeader
              disableTypography
              title={<Typography variant="h6">Policer</Typography>}
              style={{ paddingBottom: 0 }}
            />
            <CardContent style={{ paddingTop: 0, overflowY: 'auto' }}>
              <DRFTable>
                {Object.entries(priority).map(([key, item]) => {
                  let label = '';
                  if (key === '1') {
                    label = 'Normal Priority';
                  } else if (key === '2') {
                    label = 'High Priority';
                  }
                  return (
                    <DRFRow key={key}>
                      <DRFCell width={5}>
                        <Typography variant="body2">{label}</Typography>
                      </DRFCell>
                      <DRFCell width={7}>
                        <CustomLinearProgress
                          variant="buffer"
                          status={item.rxBufferTotalSize !== 0 ? 'ok' : 'error'}
                          current={item.rxBufferUsage}
                          max={item.rxBufferMaxUsage < item.rxBufferUsage
                            ? item.rxBufferUsage : item.rxBufferMaxUsage}
                          total={item.rxBufferMaxUsage > item.rxBufferTotalSize
                            ? item.rxBufferMaxUsage : item.rxBufferTotalSize}
                        />
                      </DRFCell>
                    </DRFRow>
                  );
                })}
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Valid Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.rxPolicerValidFrameCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Valid Fragments</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.rxPolicerValidFragmentCount}</Typography>
                  </DRFCell>
                </DRFRow>
              </DRFTable>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Card style={{ height }}>
            <CardHeader
              disableTypography
              title={<Typography variant="h6">Reassembly</Typography>}
              style={{ paddingBottom: 0 }}
            />
            <CardContent style={{ paddingTop: 0, overflowY: 'auto' }}>
              <DRFTable>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Reassembly Fragments</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.txReassemblyFragmentCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Dropped Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.txReassemblyDropFrameCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Data Overflow Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.txReassemblyOutOfMemoryDropFrameCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Context Rollover Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.txReassemblyContextRolloverDropFrameCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Outdated Fragments</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.txReassemblyOutdatedDropFragmentCount}</Typography>
                  </DRFCell>
                </DRFRow>
              </DRFTable>
            </CardContent>
            <CardHeader
              disableTypography
              title={<Typography variant="h6">Postprocess</Typography>}
              style={{ paddingBottom: 0 }}
            />
            <CardContent style={{ paddingTop: 0, overflowY: 'auto' }}>
              <DRFTable>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Valid Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.txPostprocessValidFrameCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Compressed Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.txPostprocessCompressedFrameCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Padded Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.txPostprocessPaddedFrameCount}</Typography>
                  </DRFCell>
                </DRFRow>
              </DRFTable>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    data ? renderData()
      : <Progress />
  );
};

export default ShareUser;
