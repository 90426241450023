import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Grid, Card, CardHeader, CardContent, IconButton, Collapse, Divider
} from '@mui/material';
import { useParams } from 'react-router';

import {
  ChevronLeft, ExpandLess
} from '@mui/icons-material';
import TimeSeries from './TimeSeries';

const useStyles = makeStyles(() => ({
  card: {
    paddingBottom: 0
  },
  cardHeader: {
    // paddingTop: 0,
    // paddingBottom: 0,
    textAlign: 'center'
  }
}));

export default function GraphExpansioPanel({
  interfaceId,
  interfaceName,
  device,
  dateRange
}) {
  const classes = useStyles();
  const { id } = useParams();
  const [visible, setVisible] = useState({
    traffic: true, latency: true, jitter: true // , status: false
  });

  useEffect(() => {
    setVisible({
      traffic: true, latency: true, jitter: true // , status: false
    });
  }, [id]);

  return (
    <Grid id="GraphContainer" container spacing={2} style={{ maxHeight: '80vh', overflow: 'auto' }}>
      <Grid item xs={12}>
        <Card elevation={0} className={classes.card}>
          <CardHeader
            title="I/O Traffic"
            className={classes.cardHeader}
            action={(
              <IconButton
                id="TrafficExpanderButton"
                onClick={() => setVisible({ ...visible, traffic: !visible.traffic })}
              >
                {visible.traffic ? <ExpandLess /> : <ChevronLeft />}
              </IconButton>
            )}
          />
          <Collapse in={visible.traffic}>
            <CardContent>
              {visible.traffic
                && (
                  <TimeSeries
                    id={interfaceId}
                    graphType="traffic"
                    interfaceName={interfaceName}
                    device={device}
                    dateRange={dateRange}
                  />
                )}
            </CardContent>
          </Collapse>
        </Card>
        <Divider variant="middle" />
      </Grid>
      <Grid item xs={12}>
        <Card elevation={0} className={classes.card}>
          <CardHeader
            title="Latency"
            className={classes.cardHeader}
            action={(
              <IconButton
                id="LatencyExpanderButton"
                onClick={() => setVisible({ ...visible, latency: !visible.latency })}
              >
                {visible.latency ? <ExpandLess /> : <ChevronLeft />}
              </IconButton>
            )}
          />
          <Collapse in={visible.latency}>
            <CardContent>
              {visible.latency
                && (
                  <TimeSeries
                    id={interfaceId}
                    graphType="latency"
                    interfaceName={interfaceName}
                    device={device}
                    dateRange={dateRange}
                  />
                )}
            </CardContent>
          </Collapse>
        </Card>
        <Divider variant="middle" />
      </Grid>
      <Grid item xs={12}>
        <Card elevation={0} className={classes.card}>
          <CardHeader
            title="Jitter"
            className={classes.cardHeader}
            action={(
              <IconButton
                id="JitterExpanderButton"
                onClick={() => setVisible({ ...visible, jitter: !visible.jitter })}
              >
                {visible.jitter ? <ExpandLess /> : <ChevronLeft />}
              </IconButton>
            )}
          />
          <Collapse in={visible.jitter}>
            <CardContent>
              {visible.jitter
                && (
                  <TimeSeries
                    id={interfaceId}
                    graphType="jitter"
                    interfaceName={interfaceName}
                    device={device}
                    dateRange={dateRange}
                  />
                )}
            </CardContent>
          </Collapse>
        </Card>
        <Divider variant="middle" />
      </Grid>
      {/* Commented out as status graphs are not yet available or will not be used */}
      {/* <Grid item xs={12}>
        <Card elevation={0} className={classes.card}>
          <CardHeader
            title="Status"
            className={classes.cardHeader}
            action={(
              <IconButton onClick={() => setVisible({ ...visible, status: !visible.status })}>
                {visible.status ? <ExpandLess /> : <ChevronLeft />}
              </IconButton>
            )}
          />
          <Collapse in={visible.status}>
            <CardContent>
              {visible.status
                && (
                  <TimeSeries graphType="status" interfaceName={interfaceName} device={device} dateRange={dateRange} />
                )}
            </CardContent>
          </Collapse>
        </Card>
      </Grid> */}
    </Grid>
  );
}
