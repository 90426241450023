import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router';

const NetworkMap = forwardRef((props, ref) => (
  <div
    ref={ref}
  >
    <RouterLink {...props} />
  </div>
));

export default NetworkMap;
