import React, { useEffect } from 'react';
import {
  Outlet, useLocation, useNavigate
} from 'react-router';
import PAGES from 'common/pages';
import useAuth from 'hooks/useAuth';

const PrivateLayoutWrapper = ({ layout: Layout }) => {
  const { isSignedIn } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isSignedIn()) {
      navigate(PAGES.SIGN_IN, { state: { from: location } });
    }
  }, [isSignedIn, location, navigate]);

  return (
    <Layout>
      <Outlet />
    </Layout>

  );
};

export default PrivateLayoutWrapper;
