import React from 'react';
import { IconButton, Tooltip, CircularProgress } from '@mui/material';
import { Refresh as RefreshIcon, Add } from '@mui/icons-material';
import Toolbar from 'components/Toolbar/Toolbar';
import { isAppAdmin } from 'helpers/permissions';
import InterfaceConfigurationCreate from './Form/InterfaceConfigurationCreate';

const InterfaceCompressionToolbar = ({
  title = '', isFetching, refetch, backLink, data = null, userData = null
}) => (
  <Toolbar
    title={title}
    backLink={backLink}
    action={(
      <>
        {data && (userData.is_staff || isAppAdmin(userData.groups)) ? (
          <InterfaceConfigurationCreate
            title="Create Compression"
            button={{
              content: (
                <>
                  <Add />
                  Create
                </>
              ),
              color: 'primary',
              variant: 'standard',
              state: 'outlined'
            }}
            refetch={refetch}
          />
        ) : null}
        {isFetching ? (
          <div style={{
            height: 48, width: 48, display: 'flex', alignItems: 'center', justifyContent: 'center'
          }}
          >
            <CircularProgress size={22} />
          </div>
        ) : (
          <Tooltip title="Sync">
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                refetch();
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        )}
      </>
    )}
  />
);
export default InterfaceCompressionToolbar;
