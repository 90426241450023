import React from 'react';
import { makeStyles } from '@mui/styles';
import { CardContent, LinearProgress, Typography } from '@mui/material';
import { Card } from 'bso-ui';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.white,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },

  loading: {
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
}));

const LinearLoading = ({ title = '' }) => {
  const classes = useStyles();
  return (
    <div>
      <Card color="secondary">
        <CardContent>
          <div className={classes.content}>
            <div className={classes.loading}>
              <div style={{ width: '240px' }}>
                <LinearProgress data-testid="loading" />
              </div>
              <Typography
                data-testid="loading-msg"
                style={{ marginTop: 8 }}
                variant="body1"
              >
                {title}
              </Typography>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default LinearLoading;
