import React from 'react';
import { useNavigate, useLocation } from 'react-router';
import { Settings } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import {
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  listItem: {
    color: theme.palette.primary.main,
  },
}));

const Configure = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();

  return (
    <ListItem
      button
      onClick={() => {
        const pathname = location.pathname.endsWith('/')
          ? `${location.pathname}configure/${data.id}`
          : `${location.pathname}/configure/${data.id}`;
        navigate(`${pathname}${location.search}`);
      }}
    >
      <ListItemIcon className={classes.listItem}><Settings /></ListItemIcon>
      <ListItemText primary="(De)compression" />
    </ListItem>
  );
};

export default Configure;
