import React from 'react';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate, useLocation } from 'react-router';
import { getParamsStrings, removeDuplicateURLSearchParams } from 'helpers/url';
import { IconButton } from 'bso-ui';

const NextPaginator = ({ next, isFetched }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNextButtonClick = () => {
    const params = new URLSearchParams(getParamsStrings(next));
    const newParams = removeDuplicateURLSearchParams(params);
    navigate(`${location.pathname}?${newParams.toString()}`);
  };

  return (
    <Tooltip title="Next">
      <div>
        <IconButton
          color="primary"
          onClick={handleNextButtonClick}
          disabled={!next || !isFetched}
        >
          <KeyboardArrowRight />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default NextPaginator;
