import React from 'react';
import { Routes, Navigate, Route } from 'react-router';

import PAGES from 'common/pages';
import PrivateLayoutWrapper from 'components/LayoutWrapper/PrivateLayoutWrapper';
import InterfaceContent from 'views/Interface/components/InterfaceContent';
import InterfaceStatusInfo from 'views/Interface/components/Information/InterfaceStatusInfo';
import InterfaceStatusConfigure from 'views/Interface/components/Configure/InterfaceStatusConfigure';
import Account from 'views/Settings/Account/Account';
import InterfacesView from 'views/Settings/Interface/InterfacesView';
import InterfacesDetailView from 'views/Settings/Interface/InterfacesDetailView';
import UsersView from 'views/Settings/User/UsersView';
import UsersDetailView from 'views/Settings/User/UsersDetailView';
import DevicesDetailView from 'views/Settings/Device/DevicesDetailView';
import DevicesView from 'views/Settings/Device/DevicesView';
import DefaultThresholdView from 'views/Settings/Device/DefaultThreshold/DefaultThresholdView';
import Logs from 'views/Settings/Logs/Logs';
import LayoutWrapper from './components/LayoutWrapper';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  // Dashboard as DashboardView,
  Settings as SettingsView,
  // Interface as InterfaceView,
  InterfaceStatusView,
  InterfaceStatusContainer,
  SignUp as SignUpView,
  ConfirmSignUp as ConfirmSignUpView,
  ResendConfirmSignUp as ResendConfirmSignUpView,
  SignIn as SignInView,
  SignOut as SignOutView,
  ForgotPassword as ForgotPasswordView,
  ResetPassword as ResetPasswordView,
  NotFound as NotFoundView,
  NetworkMap as NetworkMapView
} from './views';

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Navigate replace to={PAGES.INTERFACE_STATUS} />} />

    <Route
      path={PAGES.INTERFACE_STATUS}
      element={(
        <PrivateLayoutWrapper
          layout={MainLayout}
        />
      )}
    >
      <Route index element={<InterfaceStatusView />} />
      {/* basically fetches ids and redirects to PAGES.INTERFACE_STATUS_DETAIL / ids[0] */}

      <Route
        path=":id"
      // element={(
      //   <PrivateLayoutWrapper
      //     layout={MainLayout}
      //   />
      // )}
      >
        {/* <Route path={PAGES.INTERFACE_STATUS} element={<Navigate to={PAGES.INTERFACE_STATUS_DETAIL} />} /> */}
        {/* will never match, idk why it's here */}

        {/* PAGES.INTERFACE_STATUS_DETAIL */}
        <Route
          index
          title="Interface Status_"
          element={
            <InterfaceStatusContainer rightComponent={InterfaceContent} />
          }
        />
        {/* PAGES.INTERFACE_STATUS_INFORMATION */}
        <Route
          path="information/:iid"
          title="Interface Information_"
          element={<InterfaceStatusContainer rightComponent={InterfaceStatusInfo} />}
        />
        {/* PAGES.INTERFACE_CONFIGURE */}
        <Route
          path="configure/:iid"
          title="Interface Configuration_"
          element={<InterfaceStatusContainer rightComponent={InterfaceStatusConfigure} />}
        />
      </Route>
    </Route>

    <Route
      path={PAGES.SETTINGS}
      element={(
        <PrivateLayoutWrapper
          layout={MainLayout}
        />
      )}
    >
      <Route element={<SettingsView />}>
        <Route index element={<Navigate replace to={PAGES.ACCOUNT} />} />
        {/* PAGES.ACCOUNT */}
        <Route
          path="account"
          element={<Account />}
        />
        <Route
          path="interface-management"
        >
          {/* PAGES.INTERFACE_MGMT */}
          <Route
            index
            element={<InterfacesView />}
          />
          {/* PAGES.INTERFACE_DETAIL */}
          <Route
            path=":id"
            element={<InterfacesDetailView />}
          />
        </Route>
        <Route
          path="user-management"
        >
          {/* PAGES.USER_MGMT */}
          <Route
            index
            element={<UsersView />}
          />
          {/* PAGES.USER_DETAIL */}
          <Route
            path=":id"
            element={<UsersDetailView />}
          />
        </Route>
        <Route
          path="device-management"
        >
          {/* PAGES.DEVICE_MGMT */}
          <Route
            index
            element={
              <DevicesView />
            }
          />
          {/* PAGES.DEVICE_DETAIL */}
          <Route
            path=":id"
            element={
              <DevicesDetailView />
            }
          />
        </Route>
        {/* PAGES.DEFAULT_THRESHOLD_MGMT */}
        <Route
          path="default-threshold"
          element={
            <DefaultThresholdView />
          }
        />
        {/* PAGES.LOGS */}
        <Route
          path="logs"
          element={<Logs />}
        />
      </Route>
    </Route>
    <Route
      path={PAGES.NETWORKMAP}
      element={(
        <PrivateLayoutWrapper
          layout={MainLayout}
        />
      )}
    >
      <Route index element={<NetworkMapView />} />
    </Route>

    <Route
      path={PAGES.SIGN_UP}
      element={(
        <LayoutWrapper
          component={SignUpView}
          layout={MinimalLayout}
        />
      )}
    />
    <Route
      path={PAGES.CONFIRM_SIGN_UP}
      element={(
        <LayoutWrapper
          component={ConfirmSignUpView}
          layout={MinimalLayout}
        />
      )}
    />
    <Route
      path={PAGES.RESEND_CONFIRM_SIGN_UP}
      element={(
        <LayoutWrapper
          component={ResendConfirmSignUpView}
          layout={MinimalLayout}
        />
      )}
    />
    <Route
      path={PAGES.SIGN_IN}
      element={(
        <LayoutWrapper
          component={SignInView}
          layout={MinimalLayout}
        />
      )}
    />
    <Route
      path={PAGES.SIGN_OUT}
      element={(
        <LayoutWrapper
          component={SignOutView}
          layout={MinimalLayout}
        />
      )}
    />
    <Route
      path={PAGES.FORGOT_PASSWORD}
      element={(
        <LayoutWrapper
          component={ForgotPasswordView}
          layout={MinimalLayout}
        />
      )}
    />
    <Route
      path={PAGES.RESET_PASSWORD}
      element={(
        <LayoutWrapper
          component={ResetPasswordView}
          layout={MinimalLayout}
        />
      )}
    />
    <Route
      path={PAGES.NOT_FOUND}
      element={(
        <LayoutWrapper
          layout={MinimalLayout}
          component={NotFoundView}
        />
      )}
    />
    {/* <Redirect to={PAGES.NOT_FOUND} /> */}
    <Route path="*" element={<Navigate replace to={PAGES.NOT_FOUND} />} />
  </Routes>
);

export default AppRoutes;
