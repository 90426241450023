import React from 'react';

const LayoutWrapper = (props) => {
  const { layout: Layout, component: Component } = props;

  return (
    <Layout>
      <Component />
    </Layout>
  );
};

export default LayoutWrapper;
