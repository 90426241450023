import React, { useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router';
import PAGES from 'common/pages';
import LinearLoading from 'common/LinearLoading/LinearLoading';
import useAuth from 'hooks/useAuth';

export default function SignOut() {
  const { isSignedIn, signOut } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    signOut();
    setTimeout(
      () => {
        navigate(PAGES.SIGN_IN);
      },
      2000
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isSignedIn()) {
    return (
      <LinearLoading title="Signing out..." />
    );
  }

  // return <Redirect to={PAGES.SIGN_IN} />;
  return <Navigate replace to={PAGES.SIGN_IN} />;
}
