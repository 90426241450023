import React, { useState } from 'react';
import {
  useLocation,
  Navigate
} from 'react-router';
import { makeStyles } from '@mui/styles';
import {
  List,
  ListItem,
  Box,
  Grid,
  CircularProgress,
  Hidden,
  TextField,
} from '@mui/material';
import PAGES from 'common/pages';
import { ArrowForward } from '@mui/icons-material';
import TransitionHOC from 'components/TransitionHOC/TransitionHOC';
import useAuth from 'hooks/useAuth';
import {
  Button, Card, RouterLink, Typography
} from 'bso-ui';

const useStyles = makeStyles((theme) => ({
  grid: {
    height: '100%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    padding: theme.spacing(3)
  },
  title: {
    // textAlign: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(5)
  },
  submitButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  }
}));

const SignIn = () => {
  const classes = useStyles();
  const location = useLocation();
  const { signIn, isLoading, isSignedIn } = useAuth();
  const { from } = location.state || { from: { pathname: '/' } };

  const [formState, setFormState] = useState({
    username: '',
    password: '',
  });

  const handleChange = (name, value) => {
    setFormState({ ...formState, [name]: value });
  };

  const handleSignIn = (event) => {
    event.preventDefault();
    signIn({ username: formState.username, password: formState.password });
  };

  if (isSignedIn()) {
    return <Navigate replace to={from} />;
  }

  return (
    <>
      <TransitionHOC
        in
        mountOnEnter
        timeout={400}
        unmountOnExit
      >
        <Box>
          <Card color="gradient">
            <Box padding={2}>
              <Grid container>
                <Hidden smDown>
                  <Grid item md={6}>
                    <Box display="flex" justifyContent="flex-start" height="100%" paddingBottom={4}>
                      <Typography variant="h1">
                        Welcome to portal_
                      </Typography>
                    </Box>
                  </Grid>
                </Hidden>
                <Grid item md={6} xs={12}>
                  <Card color="white">
                    <form
                      className={classes.form}
                      onSubmit={handleSignIn}
                    >
                      <Typography
                        className={classes.title}
                        data-testid="welcome-message"
                        variant="h3"
                      >
                        Sign in
                      </Typography>
                      <div className={classes.subtitle}>
                        <Box display="flex" className={classes.subtitle}>
                          <Typography
                            color="black"
                            variant="body1"
                          >
                            {'Don\'t have an account?'} {' '}
                          </Typography>
                          <Box marginLeft={1}>
                            <RouterLink
                              to={{ pathname: PAGES.SIGN_UP, state: { slideTransition: 'right' } }}
                              data-testid="sign-up-link"
                            >
                              <Typography variant="subtitle1" color="primary" component="span">
                                Sign up
                              </Typography>
                            </RouterLink>
                          </Box>
                        </Box>
                      </div>
                      <List disablePadding>
                        <ListItem disableGutters>
                          {/* <FormField */}
                          <TextField
                            data-testid="email-input"
                            variant="outlined"
                            label="Username or Email"
                            name="username"
                            fullWidth
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                            type="string"
                            value={formState.username}
                          />
                        </ListItem>
                        <ListItem disableGutters>
                          {/* <FormField */}
                          <TextField
                            data-testid="password-input"
                            variant="outlined"
                            label="Password"
                            name="password"
                            fullWidth
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                            type="password"
                            value={formState.password}
                          />
                        </ListItem>
                        <Box display="flex">
                          <RouterLink
                            to={{
                              pathname: PAGES.FORGOT_PASSWORD,
                              state: { slideTransition: 'right' }
                            }}
                            data-testid="forgot-password-link"
                          >
                            <Typography component="span" variant="button" color="primary">
                              Forgot password?
                            </Typography>
                          </RouterLink>
                        </Box>
                      </List>
                      <Box display="flex" marginTop={4}>
                        <Button
                          id="SignInButton"
                          className={classes.submitButton}
                          data-testid="signin-button"
                          disabled={!formState.username || !formState.password || isLoading}
                          endIcon={!isLoading ? <ArrowForward /> : <CircularProgress size={20} />}
                          type="submit"
                          variant="cta"
                          state="solid"
                          color="primary"
                        >
                          Sign in now
                        </Button>
                      </Box>
                    </form>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Box>
      </TransitionHOC>
    </>
  );
};

export default SignIn;
