import React from 'react';
import { makeStyles } from '@mui/styles';

import API from 'common/api';
import useGraph from 'hooks/useGraph';
import { PlaceHolderText } from 'components/PlaceholderCard/PlaceholderCard';
import { capitalizeFirstLetter } from 'helpers/formatters';
import Graph from './Graph';

const useStyles = makeStyles(() => ({
  timeSeriesWrapper: {
    height: '300px',
    width: '100%'
  }
}));

export default function TimeSeriesWrapper({
  id, graphType, interfaceName, device, dateRange
}) {
  const classes = useStyles();
  const height = '300px';
  const params = new URLSearchParams(
    `graph=${graphType}&from_now=${dateRange}`
  );

  const query = {
    storeKey: `link-graph-${device.hostname}-${interfaceName}-${graphType}-${dateRange}`,
    apiEndpoint: `${API.GRAPH}${id}/graph/`,
  };

  const [{
    data, isLoading, isError, isFetched
  }] = useGraph(query.storeKey, query.apiEndpoint, params);

  if (isLoading) {
    return (
      <div className={classes.timeSeriesWrapper}>
        <PlaceHolderText content="Loading..." height={height} />
      </div>
    );
  }

  if (isError) {
    return (
      <div style={{ height: `calc(${height} - 100px)` }}>
        <PlaceHolderText
          id={`${capitalizeFirstLetter(graphType)}ErrorMetricsText`}
          content="Error Fetching Metrics"
          height="100%"
        />
      </div>
    );
  }

  if (data === null) {
    return (
      <div className={classes.timeSeriesWrapper}>
        <PlaceHolderText
          id={`${capitalizeFirstLetter(graphType)}EmptyMetricsText`}
          content={`No ${capitalizeFirstLetter(graphType)} Data`}
          height={height}
        />
      </div>
    );
  }

  if (isFetched) {
    return (
      <div className={classes.timeSeriesWrapper}>
        <Graph data={data.results} graphType={graphType} />
      </div>
    );
  }
}
