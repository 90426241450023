import React, { useState } from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import { List } from '@mui/material';
import {
  Menu
} from 'bso-ui';
import useUser from 'hooks/useUser';
import { isAppAdmin } from 'helpers/permissions';
import { getDeviceFeature } from 'helpers/misc';
import LinkGraph from './LinkGraph';
import Configure from './Configure';

const ActionOptions = ({ data }) => {
  const [{ data: userData }] = useUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuContent = (
    <List>
      <LinkGraph
        interfaceId={data.id}
        interfaceName={data.name}
        device={data.device}
        handleOptionClose={handleClose}
      />
      {getDeviceFeature(data?.device) === 'share' && (userData.is_staff || isAppAdmin(userData.groups)) ? (
        <Configure data={data} />
      ) : null}
    </List>
  );

  return (
    <Menu
      id="actionOptionsButton"
      open={isMenuOpen}
      anchorEl={anchorEl}
      menuName="Options"
      variant="button"
      multiple
      handleClick={handleClick}
      handleClose={handleClose}
      buttonProps={{
        variant: 'standard',
        state: 'outlined',
        endIcon: <KeyboardArrowDown />
      }}
    >
      {menuContent}
    </Menu>
  );
};

export default ActionOptions;
