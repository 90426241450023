import React from 'react';
import {
  Box,
  Grid,
  Card,
  CardHeader, CardContent
} from '@mui/material';
import Progress from 'components/Progress/Progress';
import { Typography } from 'bso-ui';
import DRFTable from 'components/DRFTable/DRFTable';
import DRFRow from 'components/DRFTable/DRFRow';
import DRFCell from 'components/DRFTable/DRFCell';

const SecureLatencyMeasure = ({ data }) => {
  const renderData = () => (
    <Box px={3}>
      <Typography variant="h5">
        Latency Measure
      </Typography>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item lg={12} xs={12}>
          <Card>
            <CardHeader
              disableTypography
              title={<Typography variant="h6">Backup Trunk</Typography>}
              style={{ paddingBottom: 0 }}
            />
            <CardContent style={{ paddingTop: 0, overflowY: 'auto' }}>
              <DRFTable>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Round Trip Time</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.lane.latencyMeasureSecondaryRTT}</Typography>
                  </DRFCell>
                </DRFRow>
              </DRFTable>
            </CardContent>
            <CardHeader
              disableTypography
              title={<Typography variant="h6">Primary Latency Gap</Typography>}
              style={{ paddingBottom: 0 }}
            />
            <CardContent style={{ paddingTop: 0, overflowY: 'auto' }}>
              <DRFTable>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Incoming</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.lane.latencyMeasureIncomingPrimaryLatencyGap}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Outgoing</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.lane.latencyMeasureOutgoingPrimaryLatencyGap}</Typography>
                  </DRFCell>
                </DRFRow>
              </DRFTable>
            </CardContent>
            <CardHeader
              disableTypography
              title={<Typography variant="h6">RTT Frames</Typography>}
              style={{ paddingBottom: 0 }}
            />
            <CardContent style={{ paddingTop: 0, overflowY: 'auto' }}>
              <DRFTable>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Generated</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.lane.latencyGeneratedFrameCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Forwarded</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.lane.latencyForwardedFrameCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Measured</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.lane.latencyMeasureFrameCount}</Typography>
                  </DRFCell>
                </DRFRow>
              </DRFTable>
            </CardContent>
            <CardHeader
              disableTypography
              title={<Typography variant="h6">Recovery</Typography>}
              style={{ paddingBottom: 0 }}
            />
            <CardContent style={{ paddingTop: 0, overflowY: 'auto' }}>
              <DRFTable>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Recovered Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.lane.recoveryRecoveredFramesCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Secondary Gaps</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.lane.recoverySecondaryGapCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Hash Overflow</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.lane.recoveryPrimaryHashMemOverflowCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Secondary Down</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.lane.recoverySecondaryDownCount}</Typography>
                  </DRFCell>
                </DRFRow>
              </DRFTable>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    data ? renderData()
      : <Progress />

  );
};

export default SecureLatencyMeasure;
