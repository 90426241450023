import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Menu as MenuIcon, ArrowDropDown, KeyboardArrowRight, ArrowForward
} from '@mui/icons-material';
import {
  Hidden, Avatar, Typography, IconButton,
  List, ListItem, ListItemAvatar, ListItemText, Divider,
  Drawer, Popover, Zoom, useMediaQuery,
  Skeleton
} from '@mui/material';
import PAGES from 'common/pages';
import CustomNavLink from 'components/CustomNavLink/CustomNavLink';
import NetworkMap from 'components/NetworkMap/NetworkMap';
import useUser from 'hooks/useUser';
import { Button } from 'bso-ui';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    backgroundColor: theme.palette.primary.main
  },
  avatarBig: {
    backgroundColor: theme.palette.primary.main
  }
}));

const Profile = () => {
  const classes = useStyles();
  const [{ data, isLoading }] = useUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = () => {
    setDrawerOpen((prev) => !prev);
  };

  useEffect(() => {
    if (anchorEl) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmDown]);

  useEffect(() => {
    if (drawerOpen) {
      toggleDrawer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMdUp]);

  const renderContent = () => (
    <>
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.avatarBig} />
          </ListItemAvatar>
          <ListItemText
            disableTypography
            primary={(
              <Typography noWrap variant="h5">
                {data && `${data.first_name} ${data.last_name}`}
              </Typography>
            )}
            secondary={(
              <Typography noWrap variant="body1" color="textSecondary">
                {data && `${data.email}`}
              </Typography>
            )}
          />
        </ListItem>
      </List>
      <Divider variant="middle" />
      {(data?.is_staff || data?.is_superuser) &&
        (<List style={{ paddingTop: 16, paddingBottom: 16 }}>
          <ListItem
            button
            component={NetworkMap}
            to={PAGES.NETWORKMAP}
            onClick={() => {
              if (isSmDown) {
                toggleDrawer();
              } else {
                handleClose();
              }
            }}
          >
            <ListItemText primary="Network Map" />
          </ListItem>
        </List>)}
      <Divider variant="middle" />
      <List style={{ paddingTop: 16, paddingBottom: 16 }}>
        <ListItem
          button
          component={CustomNavLink}
          to={data?.is_staff || data?.is_superuser ? PAGES.INTERFACE_MGMT : PAGES.SETTINGS}
          onClick={() => {
            if (isSmDown) {
              toggleDrawer();
            } else {
              handleClose();
            }
          }}
        >
          <ListItemText primary=" Settings" />
        </ListItem>
      </List>
      <Divider variant="middle" />
      <div style={{ display: 'flex', padding: 24, width: '100%' }}>
        <span style={{ flex: 1 }} />
        <Button
          variant="cta"
          state="outlined"
          color="primary"
          endIcon={<ArrowForward />}
          component={CustomNavLink}
          to={PAGES.SIGN_OUT}
        >
          Sign out
        </Button>
      </div>
    </>
  );

  return (
    <>
      <Hidden smDown>
        {isLoading ? <Skeleton height={52} width={128} /> : (
          <Button
            startIcon={<Avatar className={classes.avatar} />}
            endIcon={<ArrowDropDown />}
            style={{
              height: 48, maxWidth: 200
            }}
            onClick={handleClick}
            variant="cta"
            state="solid"
            color="primary"
          >
            <Typography variant="body1" color="inherit" noWrap style={{ textTransform: 'none' }}>
              {data && `${data.first_name} ${data.last_name}`}
            </Typography>
          </Button>
        )}
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          TransitionComponent={Zoom}
        >
          <div style={{ width: 320 }}>
            {renderContent()}
          </div>
        </Popover>
      </Hidden>
      <Hidden mdUp>
        <IconButton color="primary" onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton>
        <Drawer anchor="right" onClose={toggleDrawer} open={drawerOpen}>
          <div>
            <IconButton onClick={toggleDrawer}>
              <KeyboardArrowRight />
            </IconButton>
          </div>
          {renderContent()}
        </Drawer>
      </Hidden>
    </>
  );
};

export default Profile;
