import React from 'react';
import {
  Box,
  Grid,
  Card,
  CardHeader, CardContent
} from '@mui/material';
import Progress from 'components/Progress/Progress';
import CustomLinearProgress from 'components/CustomLinearProgress/CustomLinearProgress';
import { Typography } from 'bso-ui';
import DRFTable from 'components/DRFTable/DRFTable';
import DRFRow from 'components/DRFTable/DRFRow';
import DRFCell from 'components/DRFTable/DRFCell';
import CustomChip from 'components/CustomChip/CustomChip';

const ShareLandside = ({ data }) => {
  const height = '40vh';

  const renderData = () => (
    <Box px={3}>
      <Typography variant="h5">Landside</Typography>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item lg={6} xs={12}>
          <Card style={{ height }}>
            <CardHeader
              disableTypography
              title={<Typography variant="h6">RX</Typography>}
              style={{ paddingBottom: 0 }}
            />
            <CardContent style={{ paddingTop: 0, overflowY: 'auto' }}>
              <div style={{ paddingBottom: '16px' }}>
                <CustomLinearProgress
                  variant="buffer"
                  status={(data.rxBufferTotalSize !== 0) ? 'ok' : 'error'}
                  current={data.rxBufferUsage}
                  max={data.rxBufferMaxUsage < data.rxBufferUsage
                    ? data.rxBufferUsage : data.rxBufferMaxUsage}
                  total={data.rxBufferMaxUsage > data.rxBufferTotalSize
                    ? data.rxBufferMaxUsage : data.rxBufferTotalSize}
                />
              </div>
              <DRFTable>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Status</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <CustomChip
                      label={data?.rxLink ? 'Up' : 'Down'}
                      size="small"
                      color={data?.rxLink ? 'success' : 'error'}
                    />
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Received Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.rxFrames}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">FCS Errors</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.rxErrors}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Size Errors</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.rxMalformedErrorCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">VLAN Tag Errors</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.rxVlanTagErrorCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Unknown VLAN</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.rxUnknownVlanIdCount}</Typography>
                  </DRFCell>
                </DRFRow>
              </DRFTable>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Card style={{ height }}>
            <CardHeader
              disableTypography
              title={<Typography variant="h6">TX</Typography>}
              style={{ paddingBottom: 0 }}
            />
            <CardContent style={{ paddingTop: 0, overflowY: 'auto' }}>
              <DRFTable>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Transmitted Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.txFrames}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">FCS Errors</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.txErrors}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Reassembly Fragments</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.txReassemblyFragmentCount}</Typography>
                  </DRFCell>
                </DRFRow>
                <DRFRow>
                  <DRFCell width={5}>
                    <Typography variant="body2">Reassembly Frames</Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <Typography>{data.txReassemblyFrameCount}</Typography>
                  </DRFCell>
                </DRFRow>
              </DRFTable>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );

  return (
    data ? renderData() : <Progress />
  );
};

export default ShareLandside;
