import React from 'react';
import NoSsr from '@mui/material/NoSsr';
import TextField from '@mui/material/TextField';

const FormPassword = ({
  id,
  key,
  name,
  label,
  autoFocus = false,
  helperText,
  placeholder,
  required,
  multiline,
  maxLength,
  readOnly,
  disabled,
  error,
  value,
  onChange,
  variant,
  'data-testid': testid
}) => {
  const handleChange = (event) => {
    onChange(event.target.value, name);
  };

  return (
    <NoSsr>
      <TextField
        id={id}
        type="password"
        key={key}
        name={name}
        label={label}
        helperText={helperText}
        placeholder={placeholder}
        autoFocus={autoFocus}
        variant={variant}
        fullWidth
        multiline={multiline}
        value={value}
        required={required}
        readOnly={readOnly}
        disabled={disabled}
        onChange={handleChange}
        error={error}
        inputProps={{ 'data-testid': testid, maxLength }}
      />
    </NoSsr>
  );
};

export default FormPassword;
