import React from 'react';
import { Typography, IconButton, Tooltip } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router';

const Toolbar = ({
  title = '', backLink, backLink2, action
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      id="ToolbarContainer"
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '8px'
      }}
    >
      {backLink ? (
        <Tooltip title="Go back to list">
          <IconButton
            onClick={() => navigate(`${backLink}?${location.search}`)}
            style={{ marginRight: 8 }}
          >
            <ArrowBack />
          </IconButton>
        </Tooltip>
      ) : null}
      {backLink2 ? (
        <Tooltip title="Go back">
          <IconButton
            onClick={() => navigate(backLink2)}
            style={{ marginRight: 8 }}
          >
            <ArrowBack />
          </IconButton>
        </Tooltip>
      ) : null}
      <Typography id="HeaderTitle" variant="h3" color="primary">{title}_</Typography>
      <span style={{ flex: 1 }} />
      {action}
    </div>
  );
};

export default Toolbar;
