import { CircularProgress } from '@mui/material';
import React from 'react';

const Progress = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <CircularProgress />
  </div>
);

export default Progress;
