import React from 'react';
import ApexChart from 'react-apexcharts';
import convert from 'convert-units';

import { PlaceHolderText } from 'components/PlaceholderCard/PlaceholderCard';
import { useTheme } from '@mui/material';
import { capitalizeFirstLetter } from 'helpers/formatters';
import GraphOptions from './GraphConfig';

function cympa2apex(data, graphType) {
  const allGraphData = [];
  if (graphType === 'traffic') {
    data.forEach((item) => {
      const inData = [];
      const outData = [];

      item.values.forEach((value) => {
        inData.push([value[0], value[1]]);
        outData.push([value[0], value[2]]);
      });

      allGraphData.push(
        { name: `In: ${item.interface}`, data: inData },
        { name: `Out: ${item.interface}`, data: outData }
      );
    });
  } else if (graphType === 'status') {
    data.forEach((item) => {
      const opData = [];
      const adminData = [];

      item.values.forEach((value) => {
        opData.push([value[0], value[1]]);
        adminData.push([value[0], value[2]]);
      });

      allGraphData.push(
        { name: `Oper: ${item.interface}`, data: opData },
        { name: `Admin: ${item.interface}`, data: adminData }
      );
    });
  } else {
    // latency and jitter
    data.forEach((item) => {
      const lData = [];
      item.values.forEach((value) => {
        lData.push([value[0], value[1]]);
      });

      allGraphData.push({ name: `Latency: ${item.interface}`, data: lData });
    });
  }

  return allGraphData;
}

function formatGraphOptions(graphType) {
  let baseUnit = 'b';

  if (graphType === 'traffic') {
    baseUnit = 'b';
  } else if (graphType === 'latency' || graphType === 'jitter') {
    baseUnit = 'ns';
  }
  const options = { ...GraphOptions };

  if (graphType !== 'status') {
    options.yaxis.labels.formatter = (value) => {
      const absValue = Math.abs(value);
      const convertedValue = convert(absValue)
        .from(baseUnit)
        .toBest();

      let convertUnit;
      if (graphType === 'traffic') {
        convertUnit = convertedValue.unit.replace('b', 'bps').replace('K', 'k');
      } else {
        convertUnit = convertedValue.unit;
      }

      // eslint-disable-next-line max-len
      return `${value < 0 ? '-' : ''}${convertedValue.val.toFixed(2)} ${convertedValue.unit === 'mu' ? 'μs' : convertUnit}`;
    };
  } else {
    options.yaxis = {
      ...options.yaxis,
      tickAmount: 1,
      min: 0,
      max: 1,
      labels: {
        offsetX: 30,
        offsetY: 3,
        formatter: (value) => value
      }
    };
  }

  return options;
}

const Graph = ({ data, graphType }) => {
  const theme = useTheme();

  if (data && data.length > 0) {
    const options = formatGraphOptions(graphType);

    options.chart.foreColor = theme.palette.text.primary;
    options.chart.background = theme.palette.background.paper;

    const series = cympa2apex(data, graphType);
    return <ApexChart options={options} series={series} type="area" height="300" />;
  }
  return (
    <PlaceHolderText
      id={`${capitalizeFirstLetter(graphType)}NoDataMetricsText`}
      height="33vh"
      content="No Data Metrics"
    />
  );
};

export default Graph;
