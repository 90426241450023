import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@mui/styles';
import {
  Box,
  CardActions,
  CardContent,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import API from 'common/api';
import { PlaceHolderText } from 'components/PlaceholderCard/PlaceholderCard';
import { dateTimeFormatter } from 'helpers/formatters';
import Progress from 'components/Progress/Progress';
import { Refresh } from '@mui/icons-material';
import useList from 'hooks/useList';
import TablePaginator from 'components/TablePaginator/TablePaginator';
import useUser from 'hooks/useUser';
import { Card, IconButton, Typography } from 'bso-ui';
import { isAppAdmin } from 'helpers/permissions';
import LogsToolbar from './LogsToolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const Logs = () => {
  const classes = useStyles();
  const height = '80vh';
  const [{ data: userData }] = useUser();
  const query = {
    storeKey: 'logs',
    apiEndpoint: API.LOGS,
  };

  const [{
    data, isLoading, isError, refetch, isFetched, isFetching
  }] = useList(query.storeKey, query.apiEndpoint);

  if (isError || (isFetched && !data)) {
    return (
      <div style={{ height }}>
        <PlaceHolderText variant="h3" content="Error getting logs" height="100%" />
      </div>
    );
  }

  const renderData = () => (
    <Card
      id="LogsListCard"
      className={clsx(classes.root)}
    >
      <CardContent className={classes.content}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ flex: 1 }} />

        </div>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><Typography variant="subtitle1">Timestamp</Typography></TableCell>
                  <TableCell><Typography variant="subtitle1">Method</Typography></TableCell>
                  <TableCell><Typography variant="subtitle1">URL</Typography></TableCell>
                  <TableCell><Typography variant="subtitle1">IP</Typography></TableCell>
                  <TableCell><Typography variant="subtitle1">Username</Typography></TableCell>
                  <TableCell><Typography variant="subtitle1">Email</Typography></TableCell>
                  <TableCell><Typography variant="subtitle1">Remarks</Typography></TableCell>
                </TableRow>
              </TableHead>
              {data && data.results.length > 0
                ? (
                  <TableBody>
                    {data.results.map((item) => (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={item.timestamp}
                      >
                        <TableCell>
                          <Typography color="primary" variant="body2">
                            {dateTimeFormatter(item.timestamp, 'YYYY-MM-DD HH:mm:ss UTC')}
                          </Typography>
                        </TableCell>
                        <TableCell><Typography color="primary" variant="body2">{item.method}</Typography></TableCell>
                        <TableCell><Typography color="primary" variant="body2">{item.url}</Typography></TableCell>
                        <TableCell><Typography color="primary" variant="body2">{item.ip}</Typography></TableCell>
                        <TableCell><Typography color="primary" variant="body2">{item.username}</Typography></TableCell>
                        <TableCell>
                          <Typography color="primary" variant="body2">{item.email ? item.email : '---'}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography color="primary" variant="body2">
                            {item.remarks ? item.remarks : '---'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )
                : null}
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePaginator
          count={data.count}
          previous={data.previous}
          next={data.next}
          isFetched={isFetched}
        />
      </CardActions>
    </Card>
  );

  if (userData && (userData.is_staff || isAppAdmin(userData.groups))) {
    return (
      <Box p={3}>
        <LogsToolbar title="Logs">
          {isFetching ? (
            <div style={{
              height: 48, width: 48, display: 'flex', alignItems: 'center', justifyContent: 'center'
            }}
            >
              <CircularProgress size={22} />
            </div>
          )
            : (
              <IconButton
                id="SyncButton"
                onClick={(e) => {
                  e.preventDefault();
                  refetch();
                }}
              >
                <Refresh />
              </IconButton>
            )}
        </LogsToolbar>
        {!isLoading
          ? renderData()
          : <Progress />}
      </Box>
    );
  }

  return <PlaceHolderText content="No Permissions to view this page" height={height} />;
};

export default Logs;
