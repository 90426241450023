import React from 'react';
import { Grid } from '@mui/material';

const DRFCell = ({ children, width }) => (
  <Grid item xs={width} style={{ padding: '16px' }}>
    {children}
  </Grid>
);

export default DRFCell;
