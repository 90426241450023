import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CardActions,
  Toolbar,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  TableHead,
  Skeleton
} from '@mui/material';
import { dateTimeFormatter } from 'helpers/formatters';
import API from 'common/api';
import {
  useNavigate, useLocation, useParams,
} from 'react-router';
import { PlaceHolderText } from 'components/PlaceholderCard/PlaceholderCard';
import Progress from 'components/Progress/Progress';
import { Search } from '@mui/icons-material';
import useList from 'hooks/useList';
import TablePaginator from 'components/TablePaginator/TablePaginator';
import { Card, Typography } from 'bso-ui';
import InterfaceStatus from './InterfaceStatus';
import ActionOptions from './Actions/ActionOptions';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3)
  }
}));

const InterfaceContent = () => {
  const classes = useStyles();
  const { id } = useParams();
  const query = {
    storeKey: `interfaces-${id}`,
    apiEndpoint: `${API.INTERFACE}?device__feed=${id}&device__is_active=1`,
  };
  const height = '100vh';
  const navigate = useNavigate();
  const location = useLocation();
  // const match = useMatch();
  const [search, setSearch] = useState({ value: '', clicked: false });
  const params = new URLSearchParams(location.search);
  const [{
    data, isLoading, isError, isFetched
  }] = useList(query.storeKey, query.apiEndpoint, params, { refetchInterval: 1000 });

  useEffect(() => {
    if (id) {
      setSearch((prev) => ({ ...prev, value: params.get('search') || '' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, location]);

  const handleChange = (event) => {
    event.persist();
    setSearch((prev) => ({ ...prev, value: event.target.value }));
  };

  const handleSearch = (event) => {
    event.preventDefault();
    params.set('search', search.value || '');
    navigate(`${location?.pathname}?${params.toString()}`);
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleSearch(event);
    }
  };

  if (!id) {
    return (
      <div style={{ height: `calc(${height} - 100px)` }}>
        <PlaceHolderText variant="h3" content="Select feed" height="100%" />
      </div>
    );
  }

  if (isError) {
    return (
      <div style={{ height: `calc(${height} - 100px)` }}>
        <PlaceHolderText variant="h3" content="Error getting interfaces" height="100%" />
      </div>
    );
  }

  const renderData = () => (
    <Card id="InterfaceStatusCard">
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><Typography variant="subtitle1">Device</Typography></TableCell>
              <TableCell><Typography variant="subtitle1">Port</Typography></TableCell>
              <TableCell><Typography variant="subtitle1">Description</Typography></TableCell>
              <TableCell><Typography variant="subtitle1">Activation date</Typography></TableCell>
              <TableCell>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography variant="subtitle1">
                    Status
                  </Typography>
                </div>
              </TableCell>
              {location && location.pathname?.includes('/settings/interface-management') ? (
                <TableCell><Typography variant="subtitle1">Source</Typography></TableCell>
              ) : null}
              <TableCell><Typography variant="subtitle1">SFP Tx</Typography></TableCell>
              <TableCell><Typography variant="subtitle1">SFP Rx</Typography></TableCell>
              <TableCell><Typography variant="subtitle1">Action</Typography></TableCell>
            </TableRow>
          </TableHead>
          {isFetched ? (
            <TableBody>
              {data.results.map((item) => (
                <TableRow
                  className={classes.tableRow}
                  hover
                  key={item.id}
                >
                  <TableCell><Typography color="black" variant="body2">{item.device.hostname}</Typography></TableCell>
                  <TableCell><Typography color="black" variant="body2">{item.name}</Typography></TableCell>
                  <TableCell>
                    <Typography color="black" variant="body2">
                      {item.description ? item.description : '---'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="black" variant="body2">{item.activation_date
                      ? dateTimeFormatter(item.activation_date) : '---'}
                    </Typography>
                  </TableCell>
                  <InterfaceStatus interfaceId={item.id} />
                  <TableCell>
                    <ActionOptions data={item} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              {[...Array(10)].map((item, index) => (
                <TableRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                >
                  <TableCell><Skeleton /></TableCell>
                  <TableCell><Skeleton /></TableCell>
                  <TableCell><Skeleton /></TableCell>
                  <TableCell><Skeleton /></TableCell>
                  <TableCell><Skeleton /></TableCell>
                  {location && location.pathname?.includes('/settings/interface-management') ? (
                    <TableCell><Skeleton /></TableCell>
                  ) : null}
                  <TableCell><Skeleton /></TableCell>
                  <TableCell><Skeleton /></TableCell>
                  <TableCell><Skeleton /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </CardContent>
      <CardActions>
        <span style={{ flex: 1 }} />
        <TablePaginator
          count={data.count}
          previous={data.previous}
          next={data.next}
          isFetched={isFetched}
        />
      </CardActions>
    </Card>
  );

  return (
    <div className={classes.root}>
      <Toolbar style={{ display: 'flex', alignItems: 'center' }}>
        <Typography id="InterfaceStatusTitle" variant="h2" color="primary">Interface status_</Typography>
        <span style={{ flex: 1 }} />

        <TextField
          id="InterfaceStatusPortSearchField"
          name="search"
          label="Search port"
          color="primary"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSearch}>
                  <Search />
                </IconButton>
              </InputAdornment>),
          }}
          onKeyDown={handleKeyPress}
          onChange={handleChange}
          value={search.value}
          style={{ minWidth: 200, maxWidth: '100%', width: 400 }}
        />
      </Toolbar>
      {!isLoading && id && data
        ? (
          <Box p={3}>
            {renderData()}
          </Box>
        ) : <Progress />}

    </div>
  );
};

export default InterfaceContent;
