import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import {
  ListItem, List, ListItemText, ListItemSecondaryAction
} from '@mui/material';
import { useSnackbar } from 'notistack';
import FormFieldFactory from 'components/Form/FormFieldFactory';
import useLoading from 'context/LoadingContext';
import SnackMessage from 'components/SnackMessage/SnackMessage';
import { VITE_PORTAL_API } from 'config';
import useConnectErrorHandler from 'helpers/errorHandlers';
import PAGES from 'common/pages';
import { post, put } from 'helpers/query/fetchers';
import Form from 'components/Form/Form';
import API from 'common/api';

const initialFormValue = {
  hostname: '',
  feed: '',
  ip: '',
  port: '',
  type: '',
  is_active: false,
};

const initialFormErrorValue = {
  hostname: [],
  feed: [],
  ip: [],
  type: []
};

const DeviceCreate = ({
  title, button, data = null, formType = 'create', refetch = null
}) => {
  const [form, setForm] = useState(initialFormValue);
  const [formError, setFormError] = useState(initialFormErrorValue);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoading } = useLoading();
  const { handleError } = useConnectErrorHandler();

  useEffect(() => {
    if (data) {
      setForm(data);
      setFormError(initialFormErrorValue);
    }
    return () => { };
  }, [data]);

  const onChangeValue = (value, name) => {
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (handleClose, setSubmitting) => {
    setLoading(true);
    setSubmitting(true);
    const payload = JSON.parse(JSON.stringify(form));

    if (form.feed) payload.feed = form.feed.id;
    if (form.type) payload.type = form.type.id;

    let url;
    let msg = '';
    if (formType === 'create') {
      url = `${VITE_PORTAL_API}${API.DEVICE}`;
      post(url, payload)
        .then((result) => {
          handleClose(true);
          setForm(initialFormValue);
          setFormError(initialFormErrorValue);
          setSubmitting(false);
          navigate(`${PAGES.DEVICE_MGMT}/${result.data.id}${location.search}`);
          msg = `DEVICE ID: ${result.data.id} has been Created.`;

          enqueueSnackbar(msg, {
            content: (key) => <SnackMessage id={key} message={msg} variant="success" />
          });
        })
        .catch((error) => {
          setLoading(false);
          setSubmitting(false);
          setFormError(error.response.data);
          handleError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (formType === 'update') {
      url = `${VITE_PORTAL_API}${API.DEVICE}${data.id}/`;
      put(url, payload)
        .then((result) => {
          handleClose(true);
          setForm(initialFormValue);
          setFormError(initialFormErrorValue);
          setSubmitting(false);

          // noc.set_data(result.data);
          setForm(result.data);
          msg = `DEVICE ID: ${result.data.id} has been Updated.`;
          refetch();
          enqueueSnackbar(msg, {
            content: (key) => <SnackMessage id={key} message={msg} variant="success" />
          });
        })
        .catch((error) => {
          setLoading(false);
          setSubmitting(false);
          setFormError(error.response.data);
          handleError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const isError = (error) => error && error.length > 0;

  const renderForm = () => (
    <List disablePadding>
      <ListItem disableGutters>
        <FormFieldFactory
          type="string"
          key="hostname"
          name="hostname"
          label="Name"
          // maxLength={fields.name.max_length}
          value={form.hostname}
          onChange={onChangeValue}
          autoFocus
          required
          error={isError(formError.hostname)}
          helperText={formError.hostname ? formError.hostname[0] : null}
        />
      </ListItem>
      <ListItem disableGutters>
        <FormFieldFactory
          type="select"
          key="feed"
          name="feed"
          label="Feed"
          async
          config={{
            id_field: 'id', // fields.type.choice_source.id_field,
            label_field: 'name', // x => `${x.name} | ${x.code}`,
            url: `${VITE_PORTAL_API}${API.FEED}`, // fields.type.choice_source.url
          }}
          value={form.feed}
          onChange={onChangeValue}
          required
          error={isError(formError.feed)}
          helperText={formError.feed ? formError.feed[0] : null}
        />
      </ListItem>
      <ListItem disableGutters>
        <FormFieldFactory
          type="string"
          key="ip"
          name="ip"
          label="IP"
          value={form.ip}
          onChange={onChangeValue}
          required
          error={isError(formError.ip)}
          helperText={formError.ip ? formError.ip[0] : null}
        />
      </ListItem>
      <ListItem disableGutters>
        <FormFieldFactory
          type="number"
          key="port"
          name="port"
          label="Port"
          value={form.port}
          onChange={onChangeValue}
          required
          error={isError(formError.port)}
          helperText={formError.port ? formError.port[0] : null}
        />
      </ListItem>
      <ListItem disableGutters>
        <FormFieldFactory
          type="select"
          key="type"
          name="type"
          label="Type"
          config={{
            id_field: 'id',
            label_field: 'name',
            selectOptions: [
              { name: 'switch', id: 'switch' },
              { name: 'antenna', id: 'antenna' }
            ]
          }}
          value={form.type}
          onChange={onChangeValue}
          error={isError(formError.type)}
          helperText={formError.type ? formError.type[0] : null}
        />
      </ListItem>
      <ListItem disableGutters>
        <ListItemText primary="Is Active" />
        <ListItemSecondaryAction>
          <FormFieldFactory
            type="boolean"
            key="is_active"
            name="is_active"
            value="is_active"
            checked={form.is_active}
            setToggle={onChangeValue}
            required
          />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );

  return (
    <Form title={title} button={button} renderForm={renderForm} submitForm={handleSubmit} />
  );
};

export default DeviceCreate;
