import React from 'react';
import API from 'common/api';
import SplitView from 'components/SplitViewFeedCustom/SplitView';
import {
  useParams
} from 'react-router';
import PAGES from 'common/pages';
import Progress from 'components/Progress/Progress';
import { InterfaceProvider } from './InterfaceContext';
import InterfaceToolbar from './components/InterfaceToolbar';
// import InterfaceStatusDetailView from './components/InterfaceStatusDetailView';

const InterfaceStatusContainer = ({ rightComponent }) => {
  const { id } = useParams();

  const config = {
    pageName: 'Interface Status',
    templates: {
      key: (dataItem) => dataItem.id,
      primaryText: (dataItem) => dataItem.name,
      secondaryText: () => { },
      pageLink: (dataItem) => `${PAGES.INTERFACE_STATUS}/${dataItem.id}`,
    },
    query: {
      storeKey: 'feeds',
      apiEndpoint: API.FEED,
    },
    isSelectedRow: (dataItem) => dataItem.id.toString() === id,
    toolbarComponent: InterfaceToolbar,
    rightComponent
  };

  if (id) {
    return (
      <InterfaceProvider>
        <SplitView config={config} />
      </InterfaceProvider>
    );
  }

  return <Progress />;
};

export default InterfaceStatusContainer;
