/* eslint-disable no-shadow */
import useConnectErrorHandler from 'helpers/errorHandlers';
import { get } from 'helpers/query/fetchers';
import { removeDuplicateURLSearchParams } from 'helpers/url';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router';

const useList = (key, apiEndpoint, params, config) => {
  const location = useLocation();
  const { handleError } = useConnectErrorHandler();

  const getDataList = ({ queryKey }) => {
    const { apiEndpoint, search } = queryKey[1];
    const queryParams = new URLSearchParams(search);
    if (params) {
      params.forEach((value, key) => {
        queryParams.set(key, value);
      });
    }
    const newParams = removeDuplicateURLSearchParams(queryParams);
    return get(apiEndpoint, newParams).then((res) => res.data).catch((err) => handleError(err));
  };

  const dataList = useQuery(
    [key, { apiEndpoint, search: location.search }],
    getDataList,
    { ...config, keepPreviousData: true }
  );

  return [{ ...dataList }];
};

export default useList;
