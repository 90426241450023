import React, { useState } from 'react';
import {
  Box, Grid, Card, CardHeader, CardContent, CardActions, Tooltip
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconButton, Typography } from 'bso-ui';
import { Add, Edit, Delete } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import DeleteConfirmationDialog from 'components/DeleteConfirmationDialog/DeleteConfirmationDialog';
import { del } from 'helpers/query/fetchers';
import { VITE_PORTAL_API } from 'config';
import useConnectErrorHandler from 'helpers/errorHandlers';
import Progress from 'components/Progress/Progress';
import SnackMessage from 'components/SnackMessage/SnackMessage';
import { InfoPlaceholderCard } from 'components/PlaceholderCard/PlaceholderCard';
import API from 'common/api';
import useDetail from 'hooks/useDetail';
import DRFTable from 'components/DRFTable/DRFTable';
import DRFRow from 'components/DRFTable/DRFRow';
import DRFCell from 'components/DRFTable/DRFCell';
import CustomLinearProgress from 'components/CustomLinearProgress/CustomLinearProgress';
import DeviceThresholdCreate from '../Form/DeviceThresholdCreate';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5)
  }
}));

const DeviceThreshold = ({ deviceId, deviceType }) => {
  const height = '80vh';
  const classes = useStyles();
  const { handleError } = useConnectErrorHandler();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const query = {
    storeKey: `device-threshold-${deviceId}`,
    apiEndpoint: `${API.DEVICE_THRESHOLD}?device=${deviceId}`,
  };
  const [{
    data, isLoading, isFetching, isError, isFetched, refetch
  }] = useDetail(query.storeKey, query.apiEndpoint);

  const deleteDeviceThreshold = (id, name) => {
    setLoading(true);
    const url = `${VITE_PORTAL_API}${API.DEVICE_THRESHOLD}${id}/`;

    del(url)
      .then(() => {
        refetch();
        const msg = `DEVICE THRESHOLD ${name} has been Deleted.`;

        enqueueSnackbar(msg, {
          content: (key) => <SnackMessage id={key} message={msg} variant="success" />
        });
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getActionButtons = (threshold) => (
    <Box display="flex" alignItems="center">
      <DeviceThresholdCreate
        title="Update"
        data={threshold}
        iconButton={{
          content: <Edit />,
          color: 'primary',
          size: 'small',
          disabled: isFetching
        }}
        button={{
          content: 'Update',
          variant: 'standard',
          state: 'outlined',
          color: 'primary',
          disabled: isFetching
        }}
        formType="update"
        refetch={refetch}
      />
      <DeleteConfirmationDialog
        title="Delete"
        contentText="Are you sure you want to delete this device Threshold config?"
        confirmText="Yes"
      >
        {(confirm) => (
          <Tooltip title="Delete">
            <div>
              <IconButton
                size="small"
                color="primary"
                bgTransparent
                disabled={isFetching}
                onClick={confirm(() => deleteDeviceThreshold(threshold.id, threshold.default_threshold.name))}
              >
                <Delete />
              </IconButton>
            </div>
          </Tooltip>
        )}
      </DeleteConfirmationDialog>
    </Box>
  );

  const getCreateButton = () => (
    <DeviceThresholdCreate
      title="Create Device Threshold"
      data={{ device: { id: deviceId } }}
      button={{
        content: (
          <>
            <Add />
            Add
          </>
        ),
        color: 'primary',
        variant: 'standard',
        state: 'outlined'
      }}
      refetch={refetch}
      deviceType={deviceType}
    />
  );

  const getDependencyValues = (dependency) => (
    <div style={{ spacing: 0, padding: 0 }}>
      <Typography variant="caption">
        Object: {dependency.object ? dependency.object : 'null'}
      </Typography>
      <span>  |  </span>
      <Typography variant="caption">
        Rule: {dependency.rule}
      </Typography>
      <span>  |  </span>
      <Typography variant="caption">
        Operator: {dependency.operator}
      </Typography>
    </div>
  );

  const renderData = () => (
    <Card style={{ height, overflow: 'auto' }}>
      <CardHeader
        title={(
          <Typography variant="h4" color="primary">
            Device Thresholds
          </Typography>
        )}
        style={{ paddingBottom: 0 }}
      />
      <CardContent
        style={{
          paddingTop: 0,
          overflowY: 'auto'
        }}
      >
        <DRFTable>
          {(data && data.results.length) !== 0 ? (
            data.results.map((threshold) => {
              const thresholdValue = JSON.parse(threshold.value);
              return (
                <DRFRow key={threshold.default_threshold.name}>
                  <DRFCell width={3}>
                    <Typography title={threshold.default_threshold.help_text} variant="body2">
                      {threshold.default_threshold.name}
                    </Typography>
                  </DRFCell>
                  <DRFCell width={7}>
                    <CustomLinearProgress
                      variant="buffer"
                      status="ok"
                      current={0}
                      max={thresholdValue.warning}
                      total={thresholdValue.critical}
                      mode="threshold"
                    />
                    {thresholdValue?.dependency ? getDependencyValues(thresholdValue.dependency) : null}
                  </DRFCell>
                  <DRFCell width={2}>
                    {getActionButtons(threshold)}
                  </DRFCell>
                </DRFRow>
              );
            })
          )
            : (
              <Typography color="primary" variant="caption">
                No Device threshold configuration found.
              </Typography>
            )}
        </DRFTable>
      </CardContent>
      <CardActions>{getCreateButton()}</CardActions>
    </Card>
  );

  if (isFetching || isLoading || loading) {
    return (
      <div className={classes.root}>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item lg={12} xs={12}>
            <Progress />
          </Grid>
        </Grid>
      </div>
    );
  }

  if (data === null) {
    return <InfoPlaceholderCard content="Device ID Not found. Please select an item." height={height} />;
  }

  if (isError) {
    return <InfoPlaceholderCard content="Error getting device thresholds." height={height} />;
  }

  if (data && isFetched) {
    return (
      renderData()
    );
  }
  return <div />;
};

export default DeviceThreshold;
