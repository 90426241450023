import React, { useEffect, forwardRef } from 'react';
import {
  NavLink as RouterLink,
  useParams,
  useNavigate,
  useLocation
} from 'react-router';
import { makeStyles } from '@mui/styles';
import {
  Typography, List, ListItem, Drawer, Toolbar, ListItemText
} from '@mui/material';
import { PlaceHolderText } from 'components/PlaceholderCard/PlaceholderCard';
import Progress from 'components/Progress/Progress';
import useDetail from 'hooks/useDetail';

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,

  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: 1000
  },
  drawerContainer: {
    paddingTop: 100,
    overflow: 'auto',
    height: '100%',
  },
  listItemRoot: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  selected: {
    '&$selected': {
      borderLeft: `4px solid ${theme.palette.primary.main}`,
    }
  }
}));

const CustomRouterRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ width: '100%' }}
  >
    <RouterLink {...props} />
  </div>
));

const LeftView = ({ config }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const [{
    data, isLoading, isError
  }] = useDetail(config.query.storeKey, config.query.apiEndpoint);
  const height = '100vh';

  // const getSearchParams = (item) => {
  //   const search = new URLSearchParams(location.search);
  //   search.set('device__feed', item.id);
  //   // search.set('ordering', 'description');
  //   // search.set('device__is_active', 1);
  //   search.set('is_active', true);
  //   search.set('limit', 10);
  //   search.set('offset', 0);

  //   return search.toString();
  // };

  useEffect(() => {
    if (!id && data && data.results.length > 0) {
      navigate(`${config.templates.pageLink(data.results[0])}${location.search}`);
    }
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data]);

  if (isError) {
    return <PlaceHolderText content="Error loading data" height={height} />;
  }

  const renderData = () => {
    if (data.results.length === 0) {
      return <PlaceHolderText content="No Data" height={height} />;
    }

    return (
      <List>
        {data.results.map((item) => {
          const selected = config.isSelectedRow(item);
          return (
            <ListItem
              key={config.templates.key(item)}
              button
              component={CustomRouterRouterLink}
              to={{
                pathname: config.templates.pageLink(item),
                search: location.search // getSearchParams(item)
              }}
              selected={selected}
              classes={{
                root: classes.listItemRoot,
                selected: classes.selected
              }}
              divider
            >
              <ListItemText
                disableTypography
                primary={<Typography variant="h6">{config.templates.primaryText(item)}</Typography>}
                secondary={config.templates.secondaryText(item)}
              />
            </ListItem>
          );
        })}
      </List>
    );
  };

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerContainer}>
        <Toolbar disableGutters style={{ paddingLeft: 16 }}>
          <Typography variant="h3">Feeds</Typography>
        </Toolbar>
        {isLoading || !id ? <Progress /> : renderData()}
      </div>
    </Drawer>
  );
};

export default LeftView;
