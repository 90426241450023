import React from 'react';
import {
  Card, CardHeader, CardContent, CardActions, Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  message: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: '100%'
  }
}));

export const InfoPlaceholderCard = ({ content, height }) => {
  const classes = useStyles();

  return (
    <Card style={{ height }}>
      <CardHeader>
        <span style={{ paddingTop: '40px' }} />
      </CardHeader>
      <CardContent style={{ paddingTop: 0, overflowY: 'auto' }}>
        <div className={classes.message}>
          <Typography variant="overline">{content}</Typography>
        </div>
      </CardContent>
      <CardActions>
        <span style={{ paddingTop: '56px' }} />
      </CardActions>
    </Card>
  );
};

export const TimeslotPlaceholderCard = ({ title, content, height }) => {
  const classes = useStyles();
  return (
    <Card style={{ height }}>
      <CardHeader
        title={(
          <Typography variant="h6" color="textSecondary">
            {title}
          </Typography>
        )}
      />
      <CardContent style={{ paddingTop: 0, overflowY: 'auto' }}>
        <div className={classes.message}>
          <Typography variant="overline">{content}</Typography>
        </div>
      </CardContent>
      <CardActions>
        <span style={{ paddingTop: '37px' }} />
      </CardActions>
    </Card>
  );
};

export const OtherPlaceholderCard = ({ title, content, height }) => {
  const classes = useStyles();
  return (
    <Card style={{ height }}>
      <CardHeader
        title={(
          <Typography variant="h6" color="textSecondary">
            {title}
          </Typography>
        )}
        style={{ paddingBottom: 0 }}
      />
      <CardContent style={{ paddingTop: 0 }}>
        <div className={classes.message}>
          <Typography variant="overline">{content}</Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export const PlaceHolderText = ({
  id = null, content, variant = 'overline', height
}) => {
  const classes = useStyles();

  return (
    <div style={{ overflowY: 'auto', height: `calc(${height} /1.5)` }}>
      <div className={classes.message}>
        <Typography id={id} variant={variant}>{content}</Typography>
      </div>
    </div>
  );
};

export const PlaceHolderLeftView = ({ content, height }) => {
  const classes = useStyles();
  return (
    <Card style={{ height }}>
      <CardContent style={{ paddingTop: 0, overflowY: 'auto' }}>
        <div className={classes.message}>
          <Typography variant="overline">{content}</Typography>
        </div>
      </CardContent>
      <CardActions>
        <span style={{ paddingTop: '37px' }} />
      </CardActions>
    </Card>
  );
};
