import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Typography } from 'bso-ui';

const ConfirmationDialog = (props) => {
  const {
    title, children, open, setOpen, onConfirm, onDeny
  } = props;

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => onDeny(), 200);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">
        <Typography variant="h4" component="span">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent><Typography>{children}</Typography></DialogContent>
      <DialogActions style={{ marginTop: 16 }}>
        <Button
          onClick={handleClose}
          variant="cta"
          color="white"
          state="solid"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          variant="cta"
          color="primary"
          state="solid"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
