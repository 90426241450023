import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import {
  ListItem, List
} from '@mui/material';
import { useSnackbar } from 'notistack';
import FormFieldFactory from 'components/Form/FormFieldFactory';
import useLoading from 'context/LoadingContext';
import SnackMessage from 'components/SnackMessage/SnackMessage';
import useConnectErrorHandler from 'helpers/errorHandlers';
import { post } from 'helpers/query/fetchers';
import Form from 'components/Form/Form';
import API from 'common/api';

const initialFormValue = {
  id: '',
  mac_src: '',
  mac_dst: '',
  type: null
};

const initialFormErrorValue = {
  id: [],
  mac_src: [],
  mac_dst: [],
  type: []
};

const InterfaceConfigurationCreate = ({
  title, button, iconButton, data = null, formType = 'create', refetch = null, prefilledId = null
}) => {
  const { iid } = useParams();
  const [form, setForm] = useState(initialFormValue);
  const [formError, setFormError] = useState(initialFormErrorValue);
  const { enqueueSnackbar } = useSnackbar();
  const { setLoading } = useLoading();
  const { handleError } = useConnectErrorHandler();

  useEffect(() => {
    if (data) {
      setForm({ ...data, id: prefilledId });
      setFormError(initialFormErrorValue);
    }
    return () => { };
  }, [data, prefilledId]);

  const onChangeValue = (value, name) => {
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (handleClose, setSubmitting) => {
    setLoading(true);
    setSubmitting(true);
    const payload = JSON.parse(JSON.stringify(form));
    const { id } = payload;

    if (!id) {
      setLoading(false);
      setSubmitting(false);
      setFormError({ id: ['This field is required.'] });
    } else if (id < 1 || id > 16) {
      setLoading(false);
      setSubmitting(false);
      setFormError({ id: ['Value must be between 1-16.'] });
    } else {
      payload.type = null;
      if (form.type) {
        payload.type = form.type.name;
      }

      let url;
      let msg = '';
      if (formType === 'create') {
        url = `${API.INTERFACE_COMPRESSION_DYNAMIC(iid)}/add/`;
        post(url, payload)
          .then(() => {
            handleClose(true);
            setForm(initialFormValue);
            setFormError(initialFormErrorValue);
            setSubmitting(false);

            msg = `${payload.type} ID: ${id} has been pushed to the switch.`;
            refetch();

            enqueueSnackbar(msg, {
              content: (key) => <SnackMessage id={key} message={msg} variant="success" />
            });
          })
          .catch((error) => {
            setLoading(false);
            setSubmitting(false);
            setFormError(error.response.data);
            handleError(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const isError = (error) => error && error.length > 0;

  const renderForm = () => (
    <List disablePadding>
      <ListItem disableGutters>
        <FormFieldFactory
          type="number"
          key="id"
          name="id"
          label="ID"
          // maxLength={fields.name.max_length}
          value={form.id}
          onChange={onChangeValue}
          autoFocus
          required
          error={isError(formError.id)}
          helperText={formError.id ? formError.id[0] : null}
          placeholder="Value must be between 1-16"
        />
      </ListItem>
      <ListItem disableGutters>
        <FormFieldFactory
          type="string"
          key="mac_src"
          name="mac_src"
          label="MAC source"
          // maxLength={fields.name.max_length}
          value={form.mac_src}
          onChange={onChangeValue}
          required
          readOnly={!!data}
          error={isError(formError.mac_src)}
          helperText={formError.mac_src ? formError.mac_src[0] : null}
        />
      </ListItem>
      <ListItem disableGutters>
        <FormFieldFactory
          type="string"
          key="mac_dst"
          name="mac_dst"
          label="MAC destination"
          // maxLength={fields.name.max_length}
          value={form.mac_dst}
          onChange={onChangeValue}
          required
          readOnly={!!data}
          error={isError(formError.mac_dst)}
          helperText={formError.mac_dst ? formError.mac_dst[0] : null}
        />
      </ListItem>
      <ListItem disableGutters>
        <FormFieldFactory
          type="select"
          key="type"
          name="type"
          label="Type"
          config={{
            id_field: 'id',
            label_field: 'name',
            selectOptions: [
              { name: 'compression', id: 'compression' },
              { name: 'decompression', id: 'decompression' }
            ]
          }}
          value={form.type}
          onChange={onChangeValue}
          required
          readOnly={!!data}
          error={isError(formError.type)}
          helperText={formError.type ? formError.type[0] : null}
        />
      </ListItem>
    </List>
  );

  return (
    <Form title={title} button={button} iconButton={iconButton} renderForm={renderForm} submitForm={handleSubmit} />
  );
};

export default InterfaceConfigurationCreate;
