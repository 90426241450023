import React, { forwardRef } from 'react';
import { Fade, Slide } from '@mui/material';
import { useLocation } from 'react-router';

const TransitionHOC = forwardRef((props, ref) => {
  const location = useLocation();

  if (location.state && location.state.slideTransition) {
    window.history.pushState(null, '');
    return (
      <div ref={ref}>
        <Slide {...props} direction={location.state.slideTransition} />
      </div>
    );
  }
  return (
    <div ref={ref}>
      <Fade {...props} />
    </div>
  );
});

export default TransitionHOC;
