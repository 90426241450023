import React from 'react';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate, useLocation } from 'react-router';
import { getParamsStrings, removeDuplicateURLSearchParams } from 'helpers/url';
import { IconButton } from 'bso-ui';

const NextPaginator = ({ previous, isFetched }) => {
  const navigate = useNavigate();
  const location = useLocation();
  // const isFetching = useIsFetching();

  const handleNextButtonClick = () => {
    const params = new URLSearchParams(getParamsStrings(previous));
    const newParams = removeDuplicateURLSearchParams(params);
    navigate(`${location.pathname}?${newParams.toString()}`);
  };

  return (
    <Tooltip title="Previous">
      <div>
        <IconButton
          color="primary"
          onClick={handleNextButtonClick}
          disabled={!previous || !isFetched}
        >
          <KeyboardArrowLeft />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default NextPaginator;
