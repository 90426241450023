import React, { useState } from 'react';
import {
  useLocation, NavLink,
  Navigate
} from 'react-router';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import {
  Typography,
  List,
  ListItem,
  CircularProgress,
  Fade,
  Box,
  Grid,
  Hidden,
  TextField,
} from '@mui/material';
import PAGES from 'common/pages';
import { VITE_PORTAL_API } from 'config';
import API from 'common/api';
import SnackMessage from 'components/SnackMessage/SnackMessage';
import { useSnackbar } from 'notistack';
import { ArrowForward, ArrowBack } from '@mui/icons-material';
import TransitionHOC from 'components/TransitionHOC/TransitionHOC';
import useAuth from 'hooks/useAuth';
import {
  Card, Button, RouterLink, IconButton
} from 'bso-ui';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3)
  },
  title: {
    textAlign: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  subtitle: {
    display: 'flex',
    // textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    // paddingLeft: theme.spacing(3),
    // paddingRight: theme.spacing(3)
  },
  sendButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  }
}));

const initialFormState = {
  email: '',
  // captcha: ''
};

const ForgotPassword = () => {
  const classes = useStyles();
  const location = useLocation();
  const { isSignedIn } = useAuth();
  const { from } = location.state || { from: { pathname: '/' } };
  const [loading, setLoading] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [formState, setFormState] = useState(initialFormState);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);

  const handleChange = (name, value) => {
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const url = `${VITE_PORTAL_API}${API.FORGOT_PASSWORD}`;
    axios
      .post(
        url,
        { ...formState }
      )
      .then(() => {
        setLoading(false);
        setIsSubmitSuccess(true);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.data.error) {
          enqueueSnackbar(err.response.data.error, {
            content: (key) => (
              <SnackMessage
                data-testid="error-msg"
                id={key}
                message={err.response.data.error}
                variant="error"
              />
            )
          });
        } else {
          enqueueSnackbar(err.toString(), {
            content: (key) => (
              <SnackMessage
                data-testid="error-msg"
                id={key}
                message={err.toString()}
                variant="error"
              />
            )
          });
        }
      });
  };

  if (isSignedIn()) {
    // return <Redirect to={from} />;
    return <Navigate replace to={from} />;
  }

  if (isSubmitSuccess) {
    return (
      <Box>
        <Fade
          in
          mountOnEnter
          timeout={400}
          unmountOnExit
        >
          <div>
            <Card color="gradient">
              <Box p={3}>
                <Card color="white">
                  <div
                    className={classes.form}
                  >
                    <Typography
                      className={classes.title}
                      data-testid="welcome-message"
                      variant="h3"
                    >
                      A reset password link has been sent to your email.
                    </Typography>
                    <div className={classes.subtitle}>
                      <NavLink to={{ pathname: PAGES.SIGN_IN, state: { slideTransition: 'left' } }}>
                        <Button
                          endIcon={<ArrowForward />}
                          variant="cta"
                          state="outlined"
                          color="primary"
                        >
                          Sign in
                        </Button>
                      </NavLink>
                    </div>
                  </div>
                </Card>
              </Box>
            </Card>
          </div>
        </Fade>
      </Box>
    );
  }

  return (
    <div>
      <TransitionHOC
        in
        mountOnEnter
        timeout={400}
        unmountOnExit
      >
        <Box>
          <Card color="gradient">
            <Box p={2}>
              <Grid container>
                <Hidden smDown>
                  <Grid item md={6}>
                    <Box display="flex" flexDirection="column" alignItems="flex-start" paddingBottom={4}>
                      <Typography
                        className={classes.title}
                        data-testid="welcome-message"
                        variant="h1"
                      >
                        Recover your
                      </Typography>
                      <Typography
                        data-testid="welcome-message"
                        variant="h1"
                      >
                        account_
                      </Typography>
                    </Box>
                  </Grid>
                </Hidden>
                <Grid item md={6} xs={12}>
                  <Card color="white">
                    <form
                      className={classes.form}
                      onSubmit={handleSubmit}
                    >
                      <Box display="flex">
                        <Box>
                          <RouterLink to={{ pathname: PAGES.SIGN_IN, state: { slideTransition: 'left' } }}>
                            <IconButton color="primary">
                              <Box>
                                <ArrowBack />
                              </Box>
                            </IconButton>
                          </RouterLink>
                        </Box>
                        <Box marginTop={0.7}>
                          <RouterLink to={{ pathname: PAGES.SIGN_IN, state: { slideTransition: 'left' } }}>
                            <Typography variant="button" color="primary">
                              Back to sign in
                            </Typography>
                          </RouterLink>
                        </Box>
                      </Box>
                      <div className={classes.subtitle}>
                        <Typography
                          variant="h3"
                        >
                          Enter your email below and we&apos;ll send a link to reset your password.
                        </Typography>
                      </div>
                      <List disablePadding>
                        <ListItem disableGutters>
                          {/* <FormField */}
                          <TextField
                            data-testid="email-input"
                            label="Email address"
                            name="email"
                            onChange={(e) => handleChange(e.target.name, e.target.value)}
                            type="string"
                            fullWidth
                            value={formState.email}
                            variant="outlined"
                          />
                        </ListItem>
                      </List>
                      <Box marginTop={3}>
                        <Button
                          className={classes.sendButton}
                          data-testid="subtitle-button"
                          disabled={!formState.email || loading}
                          endIcon={!loading ? <ArrowForward /> : <CircularProgress size={20} />}
                          type="submit"
                          variant="cta"
                          state="outlined"
                          color="primary"
                        >
                          Send link
                        </Button>
                      </Box>
                    </form>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Box>
      </TransitionHOC>
    </div>
  );
};

export default ForgotPassword;
