import React from 'react';
import { TableCell, Skeleton } from '@mui/material';
import API from 'common/api';
import StatusBullet from 'components/StatusBullet/StatusBullet';
import useDetail from 'hooks/useDetail';
import ActiveSwitch from 'views/Settings/components/ActiveSwitch';
import useUser from 'hooks/useUser';
import TextTransition from 'components/Transition/TextTransition';
import { Typography } from 'bso-ui';
import { isAppAdmin } from 'helpers/permissions';
import { useLocation } from 'react-router';

const InterfaceStatus = ({ interfaceId, name }) => {
  const query = {
    storeKey: `interface-${interfaceId}`,
    apiEndpoint: API.INTERFACE_STATUS(interfaceId),
  };
  const [{
    data, isLoading, isError,
  }] = useDetail(query.storeKey, query.apiEndpoint, { refetchInterval: 1000 }, true);

  const [{ data: user }] = useUser();
  // const match = useMatch();
  const location = useLocation();

  const isActive = (rx, tx) => {
    if (rx.search('up') !== -1 || tx.search('up') !== -1) {
      return true;
    }
    return false;
  };

  const getStatus = (rx, tx) => {
    if (rx.search('up') !== -1 || tx.search('up') !== -1) {
      return (
        <StatusBullet
          color="success"
          size="md"
        />
      );
    }
    return (
      <StatusBullet
        color="danger"
        size="md"
      />
    );
  };

  if (isLoading || !data) {
    return (
      <>
        <TableCell>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Skeleton variant="circle" width={24} height={24} />
          </div>
        </TableCell>
        {location && location.pathname?.includes('/settings/interface-management') ? (
          <TableCell><Skeleton variant="text" /></TableCell>
        ) : null}
        <TableCell><Skeleton variant="text" /></TableCell>
        <TableCell><Skeleton variant="text" /></TableCell>
        {user && (isAppAdmin(user.groups) || user.is_staff)
          && (location && location.pathname?.includes('/settings/interface-management'))
          ? <TableCell> <Skeleton variant="text" /></TableCell> : null}

      </>
    );
  }

  if (isError) {
    return (
      <TableCell><Typography>Error</Typography></TableCell>
    );
  }

  return (
    <>
      <TableCell>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <TextTransition key={`transition-${interfaceId}-${isActive(data.status[0].rx, data.status[0].tx)}`}>
            {getStatus(data.status[0].rx, data.status[0].tx)}
          </TextTransition>
        </div>
      </TableCell>
      {location && location.pathname?.includes('/settings/interface-management') ? (
        <TableCell>
          <Typography color="primary" variant="body2">
            {data.status[0] && data.status[0].source ? data.status[0].source : '---'}
          </Typography>
        </TableCell>
      ) : null}
      <TableCell>
        <TextTransition key={`transition-${interfaceId}-${data.transceiver.txPwr}`}>
          <Typography color="primary" variant="body2">{data.transceiver ? data.transceiver.txPwr : '---'}</Typography>
        </TextTransition>
      </TableCell>
      <TableCell>
        <TextTransition key={`transition-${interfaceId}-${data.transceiver.rxPwr}`}>
          <Typography color="primary" variant="body2">{data.transceiver ? data.transceiver.rxPwr : '---'}</Typography>
        </TextTransition>
      </TableCell>
      {user && (user.is_staff || isAppAdmin(user.groups))
        && (location && location.pathname?.includes('/settings/interface-management'))
        ? (
          <TableCell>
            <ActiveSwitch
              id={interfaceId}
              name={name}
              isActive={isActive(data.status[0].rx, data.status[0].tx)}
            />
          </TableCell>
        )
        : null}
    </>
  );
};

export default InterfaceStatus;
