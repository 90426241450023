import React, { useState, useEffect } from 'react';
import {
  useLocation, NavLink, useParams,
  Navigate
} from 'react-router';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import {
  Typography,
  List,
  ListItem,
  CircularProgress,
  Fade,
  Box,
  Grid,
  CardContent,
  TextField,
} from '@mui/material';
import PAGES from 'common/pages';
import { VITE_PORTAL_API } from 'config';
import API from 'common/api';
import SnackMessage from 'components/SnackMessage/SnackMessage';
import { useSnackbar } from 'notistack';
import { ArrowForward } from '@mui/icons-material';
import useAuth from 'hooks/useAuth';
import {
  Card, Button
} from 'bso-ui';
import LinearLoading from 'common/LinearLoading/LinearLoading';

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.white,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  loading: {
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3)
  },
  title: {
    textAlign: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  titleError: {
    textAlign: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    color: theme.palette.error.main
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(3),
    // paddingLeft: theme.spacing(3),
    // paddingRight: theme.spacing(3)
  },
  submitButton: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  }
}));

const initialFormState = {
  password: '',
  confirm_password: ''
};

const ResetPassword = () => {
  const classes = useStyles();
  const location = useLocation();
  const { isSignedIn } = useAuth();
  const { from } = location.state || { from: { pathname: '/' } };
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [formState, setFormState] = useState(initialFormState);
  const [isCodeSuccess, setIsCodeSuccess] = useState(false);
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const { code } = useParams();

  useEffect(() => {
    setLoading(true);

    const url = `${VITE_PORTAL_API}${API.VERIFY_RESET_PASSWORD}`;
    axios
      .post(
        url,
        { code }
      )
      .then(() => {
        setLoading(false);
        setIsCodeSuccess(true);
      })
      .catch((err) => {
        setLoading(false);
        setIsCodeSuccess(false);
        if (err.response && err.response.data.code) {
          enqueueSnackbar(err.response.data.code, {
            content: (key) => (
              <SnackMessage
                data-testid="error-msg"
                id={key}
                message={err.response.data.code}
                variant="error"
              />
            )
          });
        }
      });
  }, [code, enqueueSnackbar]);

  const handleChange = (name, value) => {
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const url = `${VITE_PORTAL_API}${API.RESET_PASSWORD}`;
    axios
      .post(
        url,
        { code, ...formState }
      )
      .then(() => {
        setLoading(false);
        setIsSubmitSuccess(true);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.data.error) {
          enqueueSnackbar(err.response.data.error, {
            content: (key) => (
              <SnackMessage
                data-testid="error-msg"
                id={key}
                message={err.response.data.error}
                variant="error"
              />
            )
          });
        } else {
          enqueueSnackbar(err.toString(), {
            content: (key) => (
              <SnackMessage
                data-testid="error-msg"
                id={key}
                message={err.toString()}
                variant="error"
              />
            )
          });
        }
      });
  };

  if (isSignedIn()) {
    // return <Redirect to={from} />;
    return <Navigate replace to={from} />;
  }

  if (isSubmitSuccess) {
    return (
      <div>
        <Fade
          in
          mountOnEnter
          timeout={400}
          unmountOnExit
        >
          <div>
            <Card color="gradient">
              <Box p={2}>
                <Card color="white">
                  <div className={classes.form}>
                    <Typography
                      className={classes.title}
                      data-testid="welcome-message"
                      variant="h3"
                    >
                      Your password has been reset.
                    </Typography>
                    <Box display="flex" justifyContent="center" marginTop={5}>
                      <NavLink to={{ pathname: PAGES.SIGN_IN, state: { slideTransition: 'left' } }}>
                        <Button
                          endIcon={<ArrowForward />}
                          variant="cta"
                          state="outlined"
                          color="primary"
                        >
                          Sign in
                        </Button>
                      </NavLink>
                    </Box>
                  </div>
                </Card>
              </Box>
            </Card>
          </div>
        </Fade>
      </div>
    );
  }

  if (isCodeSuccess) {
    return (
      <div>
        <Fade
          in
          mountOnEnter
          timeout={400}
          unmountOnExit
        >
          <div>
            <Card color="gradient">
              <Box p={3}>
                <Box p={2}>
                  <Grid container>
                    <Grid item md={6}>
                      <Box display="flex" flexDirection="column" alignItems="flex-start">
                        <Typography
                          className={classes.title}
                          data-testid="welcome-message"
                          variant="h1"
                        >
                          Recover your
                        </Typography>
                        <Typography
                          data-testid="welcome-message"
                          variant="h1"
                        >
                          account_
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Card color="white">
                        <form
                          className={classes.form}
                          onSubmit={handleSubmit}
                        >
                          <Typography
                            className={classes.subtitle}
                            variant="h3"
                          >
                            Enter your new password
                          </Typography>
                          <div className={classes.subtitle} />
                          <List disablePadding>
                            <ListItem disableGutters>
                              {/* <FormField */}
                              <TextField
                                data-testid="password-input"
                                label="Password"
                                name="password"
                                fullWidth
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                type="password"
                                variant="outlined"
                                value={formState.password}
                              />
                            </ListItem>
                            <ListItem disableGutters>
                              {/* <FormField */}
                              <TextField
                                data-testid="confirm_password-input"
                                label="Confirm Password"
                                name="confirm_password"
                                fullWidth
                                onChange={(e) => handleChange(e.target.name, e.target.value)}
                                type="password"
                                variant="outlined"
                                value={formState.confirm_password}
                              />
                            </ListItem>
                          </List>
                          <Box display="flex" marginTop={3}>
                            <Button
                              className={classes.submitButton}
                              data-testid="ResetPassword-button"
                              disabled={!formState.password || !formState.confirm_password || loading}
                              endIcon={!loading ? <ArrowForward /> : <CircularProgress size={20} />}
                              type="submit"
                              variant="cta"
                              state="outlined"
                              color="primary"
                            >
                              Reset now
                            </Button>
                          </Box>
                        </form>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Card>
          </div>
        </Fade>
      </div>
    );
  }

  return (
    <div>
      {loading ? (
        <LinearLoading title=" Please wait while we confirm your account..." />
      )
        : (
          <Fade
            in
            mountOnEnter
            timeout={400}
            unmountOnExit
          >
            <div>
              <Card color="gradient">
                <Box p={3}>
                  <Card color="white">
                    <CardContent>
                      <Box>
                        <Typography
                          className={classes.titleError}
                          variant="h3"
                        >
                          There is a problem with resetting your password.
                        </Typography>
                        <Box marginTop={2}>
                          <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
                            Please get a new reset password link using the link we sent to your email.
                          </Typography>
                        </Box>
                        <Box marginTop={2}>
                          <Typography variant="body1" style={{ textAlign: 'center' }}>
                            If problem persists, contact devops@bso.co
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Card>
            </div>
          </Fade>
        )}
    </div>
  );
};

export default ResetPassword;
