import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';
import {
  // Button,
  // IconButton,
  Dialog,
  DialogContent,
  Hidden,
  Drawer,
  Typography,
  List,
  ListItem,
  Slide,
  Tooltip,
  Box
} from '@mui/material';
import { Button, IconButton } from 'bso-ui';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingBottom: 8
  },
  container: {
    minWidth: '640px',
    overflowX: 'hidden',
    maxWidth: '640px',
    padding: theme.spacing(3),
    paddingTop: 0,
    boxShadow: 'none',
  }
}));

const Transition = React.forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);

const Form = ({
  title, iconButton = false, button, renderForm, submitForm, urlOpen = false
}) => {
  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitForm(handleClose, setSubmitting);
  };

  useEffect(() => {
    if (urlOpen) setOpen(true);
  }, [urlOpen]);

  const renderFields = () => (
    <>
      <Box className={classes.header}>
        <Typography id="CreateOrUpdateHeader" variant="h4">{title}</Typography>
        <span style={{ flex: 1 }} />
        <IconButton id="CloseButton" onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <Box className={classes.container}>
        <form id="form" onSubmit={handleSubmit}>
          {renderForm !== null ? renderForm() : null}
        </form>
      </Box>
    </>
  );

  const renderActions = () => (
    <>
      <span style={{ flex: 1 }} />
      <List style={{ paddingBottom: 16 }}>
        <ListItem>
          <span style={{ height: '40px' }} />
        </ListItem>
        <ListItem>
          <Button
            id="FormSubmit"
            variant="cta"
            color="primary"
            state="outlined"
            fullWidth
            onClick={handleSubmit}
            disabled={submitting}
            endIcon={button.endIcon}
            type="submit" // set the button type is submit
            form="form"
          >
            {button.formActionButtonName ? button.formActionButtonName : button.content}
          </Button>
        </ListItem>
      </List>
    </>
  );

  return (
    <>
      {iconButton ? (
        <Tooltip title={title}>
          <div>
            <IconButton
              id="CreateOrUpdateButton"
              color={iconButton.color}
              disabled={iconButton.disabled}
              size={iconButton.size}
              bgTransparent
              style={button.customSize ? {
                maxWidth: '20px', maxHeight: '20px', minWidth: '20px', minHeight: '20px'
              } : null}
              onClick={handleClickOpen}
            >
              {iconButton.content}
            </IconButton>
          </div>
        </Tooltip>
      ) : (
        <Button
          id="CreateOrUpdateButton"
          variant={button.variant}
          color={button.color}
          state={button.state}
          disabled={button.disabled}
          style={button.customSize ? {
            maxWidth: '20px', maxHeight: '20px', minWidth: '20px', minHeight: '20px', fontSize: '12px'
          } : null}
          onClick={handleClickOpen}
        >
          {button.content}
        </Button>
      )}

      <Hidden mdUp>
        <Dialog id="Form-dialog" onClose={handleClose} open={open} TransitionComponent={Transition} fullScreen>
          <DialogContent style={{ padding: 0, overflow: 'hidden' }}>
            {renderFields()}
            {renderActions()}
          </DialogContent>
        </Dialog>
      </Hidden>

      <Hidden smDown>
        <Drawer anchor="right" onClose={handleClose} open={open}>
          {renderFields()}
          {renderActions()}
        </Drawer>
      </Hidden>
    </>
  );
};

export default Form;
