import React from 'react';
import {
  Grid
} from '@mui/material';
import Progress from 'components/Progress/Progress';
import { makeStyles } from '@mui/styles';
import useDetail from 'hooks/useDetail';
import API from 'common/api';
import { InfoPlaceholderCard } from 'components/PlaceholderCard/PlaceholderCard';
import SecureIngress from './SecureIngress';
import SecureEgress from './SecureEgress';
import SecureLatencyMeasure from './SecureLatencyMeasure';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5)
  }
}));

const SecureStatistics = ({ iid }) => {
  const height = '60vh';
  const classes = useStyles();

  const query = {
    storeKey: `interface-status-info-statistics-${iid}`,
    apiEndpoint: `${API.INTERFACE}${iid}/metrics/raw/`,
  };
  const [{
    data, isError, isFetching, isFetched
  }] = useDetail(query.storeKey, query.apiEndpoint);

  const renderData = () => (
    <Grid container spacing={3} alignItems="stretch">
      <Grid item lg={7} xs={12}>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item lg={12} xs={12}>
            <SecureIngress data={data} />
          </Grid>
          <Grid item lg={12} xs={12}>
            <SecureEgress data={data} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={5} xs={12}>
        <SecureLatencyMeasure data={data} />
      </Grid>
    </Grid>
  );

  if (isFetching) {
    return (
      <div className={classes.root}>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item lg={12} xs={12}>
            <Progress />
          </Grid>
        </Grid>
      </div>
    );
  }

  if (data === null || iid === 'no-selected') {
    return <InfoPlaceholderCard content="Interface ID Not found. Please select an item." height={height} />;
  }

  if (isError) {
    return <InfoPlaceholderCard content="Error getting interface statistics." height={height} />;
  }

  if (data && isFetched) {
    return (
      renderData()
    );
  }

  return <div />;
};

export default SecureStatistics;
