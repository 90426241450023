import React from 'react';
import { Link as RouterLink } from 'react-router';
import Profile from 'components/Profile/Profile';
import { AppBar, Logo } from 'bso-ui';
import TopbarNav from './TopbarNav';

const Topbar = () => (
  <AppBar
    color="primary"
    logo={(
      <RouterLink to="/">
        <Logo color="primary-negative" />
      </RouterLink>
    )}
    nav={<div />}
  >
    <TopbarNav />
    <Profile />
  </AppBar>
);

export default Topbar;
