import { Fade } from '@mui/material';
import React from 'react';

const TextTransition = ({ children }) => (
  <Fade in unmountOnExit timeout={800}>
    <div>
      {children}
    </div>
  </Fade>
);

export default TextTransition;
