import React from 'react';
import { makeStyles } from '@mui/styles';
import NoSsr from '@mui/material/NoSsr';
import Grid from '@mui/material/Grid';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const useStyles = makeStyles(() => ({
  textField: {
    width: '100%'
  }
}));

const FormDateTimeField = ({
  id,
  name,
  label,
  helperText,
  placeholder,
  autoFocus = false,
  fullWidth,
  required,
  readOnly,
  disabled,
  clearable,
  disableFuture,
  value = null,
  setDateValue,
  format = 'MM/DD/YYYY HH:mm',
  // variant = 'dialog',
  inputVariant,
  disableToolbar,
  error
}) => {
  const classes = useStyles();

  return (
    <NoSsr>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {/* <MuiPickersUtilsProvider> */}
        <Grid container justify="space-around">
          <DateTimePicker
            id={id}
            name={name}
            label={label}
            helperText={helperText}
            placeholder={placeholder}
            fullWidth={fullWidth}
            required={required}
            readOnly={readOnly}
            disabled={disabled}
            clearable={clearable}
            disableFuture={disableFuture}
            value={value}
            onChange={(date) => {
              setDateValue(date, name);
            }}
            autoFocus={autoFocus}
            // minDate={new Date()}
            ampm={false}
            format={format}
            // variant={variant}
            inputVariant={inputVariant}
            disableToolbar={disableToolbar}
            className={classes.textField}
            error={error}
          />
        </Grid>
      </LocalizationProvider>
    </NoSsr>
  );
};

export default FormDateTimeField;
