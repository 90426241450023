import React from 'react';
import { makeStyles } from '@mui/styles';
import { Footer } from 'layouts/Minimal/components';
import { Box } from '@mui/material';
import { Topbar } from './components';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    overflowX: 'hidden',
    // paddingTop: 100
    backgroundImage: 'url(/images/background.png)',
  },
  content: {
    height: 'calc(100% - 220px)',
    marginTop: 100,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
}));

const Minimal = (props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Topbar />
      <main className={classes.content}>
        <Box
          display="flex"
          height="calc(100%)"
          alignItems="center"
          justifyContent="center"
        >
          <Box m="auto">
            {children}
          </Box>
        </Box>
      </main>
      <Box bottom={0} display="flex">
        <Box m="auto">
          <Footer />
        </Box>
      </Box>
    </div>
  );
};

export default Minimal;
