import React, {
  useState, useEffect, useCallback
} from 'react';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { get } from 'helpers/query/fetchers';

const SelectFilter = ({
  value, name, label, onChange, async, variant, config
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(null);
  const loading = open && options.length === 0;

  const getData = useCallback(
    (id) => {
      // const url = `${config.url}?id=${id}`;
      const url = `${config.url}${id}/`;

      return get(url)
        .then((res) => {
          const tmpList = [];
          const item = res.data;
          // res.data.forEach((item) => {
          let newLabel = '';
          if (Array.isArray(config.label_field)) {
            config.label_field.forEach((labelFieldItem) => {
              newLabel = `${newLabel} ${item[labelFieldItem]}`;
            });
          } else if (typeof config.label_field === 'function') {
            newLabel = config.label_field(item);
          } else {
            newLabel = item[config.label_field];
          }
          tmpList.push({ value: item[config.id_field], label: newLabel });

          // [{value, label}]
          // });
          return tmpList;
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    [config]
  );

  const getAsyncData = useCallback(
    (search) => {
      get(`${config.url}?search=${search}`)
        .then((res) => {
          const tmpList = [];
          res.data.results.forEach((item) => {
            let newLabel = '';
            if (Array.isArray(config.label_field)) {
              config.label_field.forEach((labelFieldItem) => {
                newLabel = `${newLabel} ${item[labelFieldItem]}`;
              });
            } else if (typeof config.label_field === 'function') {
              newLabel = config.label_field(item);
            } else {
              newLabel = item[config.label_field];
            }
            tmpList.push({ value: item[config.id_field], label: newLabel });
            // [{value, label}]
          });
          setOptions(tmpList);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    [config]
  );

  const convertToOption = (target, data) => {
    const isValueNaN = Number.isNaN(parseInt(target, 10));
    if (!isValueNaN) {
      data.forEach((item) => {
        if (item.value === parseInt(target, 10)) {
          setSelected(item);
        }
      });
    } else {
      data.forEach((item) => {
        if (item.value === target) {
          setSelected(item);
        }
      });
    }
  };

  const handleChange = (selectedValue) => {
    if (selectedValue) {
      onChange(selectedValue.value, name);
    } else {
      onChange('', name);
      setSelected(null);
    }
  };

  const handleInputChange = (event) => {
    if (event) {
      getAsyncData(event.target.value);
    }
  };

  useEffect(() => {
    if (config && config.selectOptions) setOptions(config.selectOptions);
  }, [config]);

  useEffect(() => {
    if (async) {
      if (!loading) {
        return undefined;
      }
      getAsyncData('');
      return () => { };
    }
    return () => { };
  }, [loading, async, getAsyncData]);

  useEffect(() => {
    if (value !== '') {
      if (async) {
        getData(value).then((data) => {
          if (data) convertToOption(value, data);
        });
      } else {
        convertToOption(value, config.selectOptions);
      }
    } else {
      setSelected(null);
    }
  }, [value, getData, async, config.selectOptions]);

  if (async) {
    return (
      <Autocomplete
        id="SelectFilter-demo"
        style={{ width: '100%' }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        disableClearable
        value={selected}
        onChange={(event, newValue) => handleChange(newValue)}
        onInputChange={handleInputChange}
        isOptionEqualToValue={(option, newValue) => option.label === newValue.label}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => {
          const { key, ...restProps } = props;
          return <li key={key} {...restProps}>{option.label}</li>;
        }}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            fullWidth
            variant={variant}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
    );
  }
  return (
    <Autocomplete
      id={name}
      style={{ width: '100%' }}
      autoSelect
      disableClearable
      clearOnEscape
      value={selected}
      onChange={(event, newValue) => handleChange(newValue)}
      isOptionEqualToValue={(option, newValue) => option[config.id_field] === newValue[config.id_field]}
      getOptionLabel={(option) => {
        if (Array.isArray(config.label_field)) {
          let newLabel = '';
          config.label_field.forEach((labelFieldItem) => {
            newLabel = `${newLabel} ${option[labelFieldItem]}`;
          });
          return newLabel;
        }
        return option[config.label_field];
      }}
      renderOption={(props, option) => {
        const { key, ...restProps } = props;
        if (Array.isArray(config.label_field)) {
          let newLabel = '';
          config.label_field.forEach((labelFieldItem) => {
            newLabel = `${newLabel} ${option[labelFieldItem]}`;
          });
          return <li key={key} {...restProps}>{newLabel}</li>;
        }
        return <li key={key} {...restProps}>{option[config.label_field]}</li>;
      }}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          label={label}
          fullWidth
          variant={variant}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'disabled' // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default SelectFilter;
