import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@mui/styles';
import {
  Box,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import API from 'common/api';
import { PlaceHolderText } from 'components/PlaceholderCard/PlaceholderCard';
import Progress from 'components/Progress/Progress';
import useList from 'hooks/useList';
import TablePaginator from 'components/TablePaginator/TablePaginator';
import useUser from 'hooks/useUser';
import { Card, Typography, RouterLink } from 'bso-ui';
import StatusBullet from 'components/StatusBullet/StatusBullet';
import TextTransition from 'components/Transition/TextTransition';
import PAGES from 'common/pages';
import { isAppAdmin } from 'helpers/permissions';
import { getDeviceFeature } from 'helpers/misc';
import DeviceToolbar from './DeviceToolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2)
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const DevicesList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const height = '80vh';
  const [{ data: userData }] = useUser();
  const query = {
    storeKey: 'device-list',
    apiEndpoint: API.DEVICE,
  };
  const [{
    data, isLoading, isError, refetch, isFetched, isFetching
  }] = useList(query.storeKey, query.apiEndpoint);

  if (isError || (isFetched && !data)) {
    return (
      <div style={{ height }}>
        <PlaceHolderText variant="h3" content="Error getting devices" height="100%" />
      </div>
    );
  }

  const renderData = () => (
    <Card
      id="DevicesListCard"
      className={clsx(classes.root)}
    >
      <CardContent className={classes.content}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ flex: 1 }} />

        </div>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell><Typography variant="subtitle1">Feed</Typography></TableCell>
                  <TableCell><Typography variant="subtitle1">Hostname</Typography></TableCell>
                  <TableCell><Typography variant="subtitle1">IP</Typography></TableCell>
                  <TableCell><Typography variant="subtitle1">Port</Typography></TableCell>
                  <TableCell><Typography variant="subtitle1">Feature</Typography></TableCell>
                  <TableCell><Typography variant="subtitle1">Type</Typography></TableCell>
                  <TableCell><Typography variant="subtitle1">Status</Typography></TableCell>
                </TableRow>
              </TableHead>
              {data && data.results.length > 0
                ? (
                  <TableBody>
                    {data.results.map((device) => (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={device.id}
                        onDoubleClick={() => {
                          navigate(`${PAGES.DEVICE_MGMT}/${device.id}${location.search}`);
                        }}
                      >
                        <TableCell>
                          <Typography>
                            {device.feed?.name ? device.feed?.name : '---'}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <RouterLink
                            disableUnderline
                            to={{
                              pathname: `${PAGES.DEVICE_MGMT}/${device.id}`,
                              search: location.search
                            }}
                            color="primary"
                            variant="body2"
                          >
                            <Typography color="primary" variant="body2" component="span">{device.hostname}</Typography>
                          </RouterLink>
                        </TableCell>
                        <TableCell>
                          <Typography color="primary" variant="body2">{device.ip}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography color="primary" variant="body2">{device.port}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography color="primary" variant="body2">
                            {device ? getDeviceFeature(device) : '---'}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography color="primary" variant="body2">
                            {device.type ? device.type : '---'}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <div style={{ display: 'flex' }}>
                            <TextTransition key={`status-${device.id}-${device.is_active}`}>
                              {device.is_active ? (
                                <Typography color="primary" variant="body2">
                                  <StatusBullet color="success" size="sm" /> ACTIVE
                                </Typography>
                              ) : (
                                <Typography color="primary" variant="body2">
                                  <StatusBullet color="danger" size="sm" /> INACTIVE
                                </Typography>
                              )}
                            </TextTransition>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )
                : null}
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePaginator
          count={data.count}
          previous={data.previous}
          next={data.next}
          isFetched={isFetched}
        />
      </CardActions>
    </Card>
  );

  if (userData && (userData.is_staff || isAppAdmin(userData.groups))) {
    return (
      <Box p={3}>
        <DeviceToolbar title="Devices" isFetching={isFetching} refetch={refetch} data={data} userData={userData} />
        {!isLoading
          ? renderData()
          : <Progress />}
      </Box>
    );
  }

  return <PlaceHolderText content="No permissions to view this page" height={height} />;
};

export default DevicesList;
