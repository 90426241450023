import React from 'react';
import Switch from '@mui/material/Switch';

const FormSwitch = ({
  id, name, disabled, checked, value, setToggle, size
}) => (
  <Switch
    id={id}
    name={name}
    color="primary"
    disabled={disabled}
    checked={checked}
    value={value}
    size={size}
    onChange={(event) => setToggle(event.target.checked, name)}
  />
);

export default FormSwitch;
