import React, { useEffect } from 'react';
import { useLocation, useNavigate, } from 'react-router';
import { PlaceHolderText } from 'components/PlaceholderCard/PlaceholderCard';
import API from 'common/api';
import PAGES from 'common/pages';
import useList from 'hooks/useList';

const InterfaceStatusView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = {
    storeKey: 'feeds',
    apiEndpoint: API.FEED
  };
  const [{
    data, isLoading, isError
  }] = useList(query.storeKey, query.apiEndpoint);

  useEffect(() => {
    if (data?.results?.length > 0) {
      navigate(`${PAGES.INTERFACE_STATUS}/${data.results[0].id}${location.search}`, { replace: true });
    }
  }, [data, navigate, location.search]);

  if (isLoading) {
    return <div><PlaceHolderText content="Loading..." height="80vh" /></div>;
  }

  if (isError) {
    return <div><PlaceHolderText content="There was an unexpected Error." height="80vh" /></div>;
  }

  if (data.count === 0) {
    return <div><PlaceHolderText content="NO FEED TO DISPLAY" height="80vh" /></div>;
  }
  return null;
};

export default InterfaceStatusView;
