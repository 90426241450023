import React from 'react';
// import React { forwardRef, useEffect, useState } from 'react';
// import {
//   Hidden, Typography, Zoom
// } from '@mui/material';
// import { makeStyles, useTheme } from '@material-ui/styles';
// import CustomNavLink from 'components/CustomNavLink/CustomNavLink';
// import PAGES from 'common/pages';
// import { useLocation } from 'react-router';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     // boxShadow: 'none',
//     backgroundColor: theme.palette.background.paper
//   },
//   button: {
//     color: theme.palette.bsoShadow,
//     padding: '10px 32px',
//     textTransform: 'none',
//     letterSpacing: 0,
//     // width: '100%',
//     height: '100%',
//     fontWeight: theme.typography.fontWeightMedium,
//     '&:hover': {
//       color: theme.palette.primary.main,
//     }
//   },
//   active: {
//     color: theme.palette.primary.main,
//     fontWeight: theme.typography.fontWeightMedium,
//   },
//   underline: {
//     border: `1px solid ${theme.palette.primary.main}`,
//     width: '70%',
//   },
// }));

// const NavButton = forwardRef((props, ref) => {
//   const theme = useTheme();
//   const classes = useStyles();
//   const location = useLocation();
//   const [isActive, setIsActive] = useState(false);

//   useEffect(() => {
//     if (location.pathname.startsWith(props.to)) {
//       setIsActive(true);
//     } else {
//       setIsActive(false);
//     }
//   }, [location, props]);

//   return (
//     <div
//       ref={ref}
//       style={{
//         display: 'flex', flexDirection: 'column', alignItems: 'center', height: 'calc(100%)'
//       }}
//     >
//       <span style={{ flex: 1 }} />
//       <Typography
//         {...props}
//       >
//         {props.children}
//       </Typography>
//       <span style={{ flex: 1 }} />
//       {isActive ? (
//         <Zoom in unmountOnExit>
//           <div className={classes.underline} />
//         </Zoom>
//       )
//         : <div style={{ border: `1px solid ${theme.palette.white}`, width: '70%' }} />}
//     </div>
//   );
// });

const TopbarNav = () => (
  <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
    {/* <Hidden smDown>
      <NavButton
        activeClassName={classes.active}
        className={classes.button}
        component={CustomNavLink}
        to={PAGES.INTERFACE_STATUS}
      >
        Interface Status
      </NavButton>
      <NavButton
        activeClassName={classes.active}
        className={classes.button}
        component={CustomNavLink}
        to={PAGES.ICE}
      >
        ICE Status
      </NavButton>
    </Hidden> */}
  </div>
);
export default TopbarNav;
