import React from 'react';
import { useLocation, useParams } from 'react-router';
import { Grid } from '@mui/material';
import Toolbar from 'components/Toolbar/Toolbar';
import Progress from 'components/Progress/Progress';
import { Typography } from 'bso-ui';
import { makeStyles } from '@mui/styles';
import { getBackInterfaceStatusUrl } from 'helpers/url';
import { getDeviceFeature } from 'helpers/misc';
import useDetail from 'hooks/useDetail';
import API from 'common/api';
import PAGES from 'common/pages';
import { InfoPlaceholderCard } from 'components/PlaceholderCard/PlaceholderCard';
import InterfaceStatusInfoGeneral from './InterfaceStatusInfoGeneral';
import ShareStatistics from '../Statistics/Share/ShareStatistics';
import SecureStatistics from '../Statistics/Secure/SecureStatistics';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5)
  }
}));

const InterfaceStatusInfo = () => {
  const height = '80vh';
  const { iid } = useParams();
  const classes = useStyles();
  const location = useLocation();

  const query = {
    storeKey: `interface-status-info-${iid}`,
    apiEndpoint: `${API.INTERFACE}${iid}/`,
  };
  const [{
    data, isError, isFetching
  }] = useDetail(query.storeKey, query.apiEndpoint);

  const renderData = () => {
    if (getDeviceFeature(data?.device) === 'share') {
      return <ShareStatistics iid={iid} />;
    }
    if (getDeviceFeature(data?.device) === 'secure') {
      return <SecureStatistics iid={iid} />;
    }
    if (Object.keys(data).length === 0) {
      return <InfoPlaceholderCard content="No interface information." height={height} />;
    }
    return (<InfoPlaceholderCard content="No device feature. Misconfigured device." height={height} />);
  };

  if (isFetching) {
    return (
      <div className={classes.root}>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item lg={12} xs={12}>
            <Progress />
          </Grid>
        </Grid>
      </div>
    );
  }

  if (data === null || iid === 'no-selected') {
    return <InfoPlaceholderCard content="Interface ID Not found. Please select an item." height={height} />;
  }

  if (isError) {
    return <InfoPlaceholderCard content="Error getting interface information." height={height} />;
  }

  if (data) {
    return (
      <div className={classes.root}>
        <Toolbar
          title="Interface Information"
          backLink={`${PAGES.INTERFACE_STATUS}/${getBackInterfaceStatusUrl(location.pathname)}`}
        >
          <Typography variant="h2" color="primary">Interface Information_</Typography>
        </Toolbar>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item lg={12} xs={12}>
            <InterfaceStatusInfoGeneral data={data} />
          </Grid>
          <Grid item lg={12} xs={12}>
            {renderData()}
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div />
  );
};

export default InterfaceStatusInfo;
